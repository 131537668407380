import Vue from 'vue'
const listners = []
Vue.directive('mousemove', {
  inserted(el, binding, vnode) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('mousemove', f)
      }
    }
    window.addEventListener('mousemove', f)
    listners.push({
      uid: vnode.context._uid,
      handler: f,
    })
  },

  unbind(_el, _binding, vnode) {
    if (listners !== 0) {
      listners
        .filter((el) => el.uid === vnode.context._uid)
        .forEach((listner, ind) => {
          window.removeEventListener('mousemove', listner.handler)
          listners.splice(ind, 1)
        })
    }
  },
})
