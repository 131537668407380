export const state = () => ({
  isUserMenuVisible: false,
})

export const getters = {
  getVisible: (state) => {
    return state.isUserMenuVisible
  },
}

export const mutations = {
  SET_VISIBLE: (state, payload) => {
    state.isUserMenuVisible = payload
  },
  TOGGLE_VISIBLE: (state) => {
    state.isUserMenuVisible = !state.isUserMenuVisible
  },
}
