import Vue from 'vue'

export const format = function (value, titles) {
  const num = isNaN(value)
    ? Number(
        value
          .replace('−', '-')
          .replace(/^(-)|[.,](?=[^.,]*[.,](?!$))|[,.]+$|[^0-9.,-]+/g, '$1')
      )
    : value

  const number = Math.abs(num)

  if (!Number.isInteger(number)) {
    return num < 0 ? `−${value * -1} ${titles[2]}` : `${value} ${titles[2]}`
  } else {
    return num < 0
      ? `−${
          isNaN(value) ? value.replace('−', '').replace('-', '') : value * -1
        } ${
          titles[
            number % 100 > 4 && number % 100 < 20
              ? 2
              : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
          ]
        }`
      : `${value} ${
          titles[
            number % 100 > 4 && number % 100 < 20
              ? 2
              : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
          ]
        }`
  }
}
/**
 * Фильтр для вывода корректного текста в зависимости от кол-ва (склоненение)
 */
Vue.filter('formatText', (value, titles) => {
  return format(value, titles)
})
