import Vue from 'vue'

Vue.directive('in-view', {
  bind: (el, binding) => {
    const animateOnScrollObserver = new IntersectionObserver(
      (entries, animateOnScrollObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateOnScrollObserver.unobserve(entry.target)

            binding.value({ isInView: true })
          } else {
            binding.value({ isInView: false })
          }
        })
      }
    )
    animateOnScrollObserver.observe(el)
  },
})
