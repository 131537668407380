import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export class UserMenu {
  private _store

  constructor(store: any) {
    this._store = store
  }

  get isVisible(): boolean {
    return this._store.getters['user-menu/getVisible']
  }

  get close() {
    return () => {
      this._store.commit('user-menu/SET_VISIBLE', false)
    }
  }

  get open() {
    return () => {
      this._store.commit('user-menu/SET_VISIBLE', true)
    }
  }

  get toggle() {
    return () => {
      this._store.commit('user-menu/TOGGLE_VISIBLE')
    }
  }
}

export default defineNuxtPlugin(({ store }, inject) => {
  inject('usermenu', new UserMenu(store))
})
