export const state = () => ({
  isHeaderTopPromoVisible: false,
  headerTopPromo: null,
})

export const getters = {
  getHeaderTopPromoVisible: (state) => {
    return state.isHeaderTopPromoVisible
  },
  getHeaderTopPromo: (state) => {
    return state.headerTopPromo
  },
}

export const mutations = {
  SET_TOP_HEADER_VISIBLE: (state) => {
    state.isHeaderTopPromoVisible = true
  },
  SET_TOP_HEADER_UNVISIBLE: (state) => {
    state.isHeaderTopPromoVisible = false
  },
  SET_TOP_HEADER: (state, payload) => {
    state.headerTopPromo = payload
  },
}

export const actions = {
  async initPromo({ state, commit }) {
    let topHeaderPromo = null
    if (!this.$auth.loggedIn) {
      topHeaderPromo = await this.$axios.$get(
        '/promo/position/TOP_HEADER-ANONYM'
      )
    } else if (this.$auth.loggedIn && !!this.$auth.user?.isClient) {
      topHeaderPromo = await this.$axios.$get(
        '/promo/position/TOP_HEADER-BUSINESS'
      )
    } else if (this.$auth.loggedIn && this.$auth.user.type === 'COMPANY') {
      topHeaderPromo = await this.$axios.$get(
        '/promo/position/TOP_HEADER-COMPANY'
      )
    } else {
      topHeaderPromo = await this.$axios.$get('/promo/position/TOP_HEADER')
    }
    commit('SET_TOP_HEADER', topHeaderPromo || null)
    const promoStatus = this.$cookies.get('headerTopPromoClose')
    if (
      !topHeaderPromo ||
      (promoStatus && promoStatus === state.headerTopPromo.key)
    ) {
      commit('SET_TOP_HEADER_UNVISIBLE')
    } else {
      commit('SET_TOP_HEADER_VISIBLE')
    }
  },
  closeHeaderTopPromo({ state, commit }) {
    this.$cookies.set('headerTopPromoClose', state.headerTopPromo.key, {
      path: '/',
      maxAge: 60 * 60 * 24,
    })
    commit('SET_TOP_HEADER_UNVISIBLE')
  },
}
