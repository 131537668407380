import EditorJS from '@editorjs/editorjs'
import Underline from '@editorjs/underline'
import Header from '@editorjs/header'
import NestedList from '@editorjs/nested-list'
import Table from '@editorjs/table'
import ImageTool from '@editorjs/image'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import YoutubeEmbed from 'editorjs-youtube-embed'
// import FacebookEmbed from 'editorjs-facebook-embed'
// import TikTokEmbed from 'editorjs-tiktok-embed'
const Marker = require('@editorjs/marker')
const Quote = require('@editorjs/quote')
const Strikethrough = require('editorjs-strikethrough')
const Hyperlink = require('editorjs-hyperlink')
const Paragraph = require('editorjs-paragraph-with-alignment')
const Delimiter = require('@editorjs/delimiter')
const AlignmentTuneTool = require('editorjs-text-alignment-blocktune')
const Embed = require('@editorjs/embed')
const isDev = process.env.NODE_ENV !== 'production'

export default (_context, inject) => {
  const defaultOptions = {
    id: '',
    data: {},
    onChange: () => {},
  }

  const editor = (options = defaultOptions) => {
    return new EditorJS({
      placeholder: 'Нажмите Tab или плюс для выбора инструмента',
      holder: options.id,
      inlineToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'marker',
        // 'hyperlink',
        'link',
      ],
      tools: {
        marker: {
          class: Marker,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+M',
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: true,
              // instagram: true,
              // facebook: true,
              twitter: true,
              // tiktok: true,
            },
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          placeholder: 'Нажмите Tab или плюс для выбора инструмента',
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="M25.475 14.324h-4.57V27h-1.866V14.324h-4.56v-1.543h10.996v1.543Z" fill="#828282"/></svg>',
            title: 'Текст',
          },
        },
        underline: Underline,
        strikethrough: {
          class: Strikethrough,
          shortcut: 'CMD+SHIFT+X',
        },
        header: {
          class: Header,
          tunes: ['anyTuneName'],
          config: {
            placeholder: 'Напишите заголовок',
            levels: [2, 3, 4],
            defaultLevel: 2,
          },
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".509" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="M25.653 13v14.58H23.684v-6.675h-7.218v6.675H14.5V13.002h1.967v6.171h7.22V13h1.966Z" fill="#828282"/></svg>',
            title: 'Заголовок',
            type: 'Base',
          },
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".509" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.143 28.071a.892.892 0 0 1 .893-.892h16.071a.893.893 0 1 1 0 1.785H16.036a.893.893 0 0 1-.893-.893Zm0-7.142a.893.893 0 0 1 .893-.893h16.071a.893.893 0 0 1 0 1.785H16.036a.892.892 0 0 1-.893-.892Zm0-7.143a.892.892 0 0 1 .893-.893h16.071a.893.893 0 1 1 0 1.786H16.036a.893.893 0 0 1-.893-.893ZM9.786 15.57a1.786 1.786 0 1 0 0-3.571 1.786 1.786 0 0 0 0 3.571Zm0 7.143a1.786 1.786 0 1 0 0-3.572 1.786 1.786 0 0 0 0 3.572Zm0 7.143a1.786 1.786 0 1 0 0-3.571 1.786 1.786 0 0 0 0 3.571Z" fill="#828282"/></svg>',
            title: 'Список',
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".509" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="M9.856 15.984h5.64v1.563h-5.64v-1.563Zm13.648 0h5.884v1.563h-5.884v-1.563Zm-6.445 0h4.882v1.563H17.06v-1.563Zm0 5.47h4.882v1.562H17.06v-1.563Zm6.445 0h5.884v1.562h-5.884v-1.563Zm-13.648 0h5.64v1.562h-5.64v-1.563Zm7.203-3.907V11.37h-1.563v16.26h1.563V17.547Zm6.445-1.563V11.37H21.94v16.26h1.563V15.984Z" fill="#828282"/></svg>',
            title: 'Таблица',
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Текст цитаты',
            captionPlaceholder: 'Укажите автора цитаты (не обязательно)',
          },
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".509" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="m30.865 16.084-.026-.175-.027.007a4.688 4.688 0 1 0-4.583 5.667c.233 0 .456-.035.678-.067a3.721 3.721 0 0 1-.265.708c-.119.32-.304.599-.489.88-.154.302-.426.507-.626.767-.209.252-.494.42-.72.629-.222.219-.513.328-.744.482-.242.139-.452.292-.677.365l-.562.231-.494.205.505 2.02.621-.15c.2-.05.442-.108.718-.178.282-.052.584-.195.919-.325.33-.149.715-.248 1.07-.486.36-.228.773-.417 1.137-.721.353-.314.78-.586 1.094-.984.344-.373.683-.764.947-1.21.305-.425.512-.892.731-1.353.198-.462.357-.934.488-1.392.247-.919.357-1.792.4-2.538.035-.748.014-1.37-.03-1.82a7.347 7.347 0 0 0-.065-.562Zm-11.458 0-.026-.175-.028.007a4.687 4.687 0 1 0-4.582 5.667c.232 0 .455-.035.677-.067a3.728 3.728 0 0 1-.264.708c-.12.32-.305.599-.489.88-.154.302-.426.507-.626.767-.21.252-.495.42-.72.629-.223.219-.513.328-.745.482-.241.139-.452.292-.677.365l-.561.231c-.314.128-.493.203-.493.203l.504 2.02.622-.15c.2-.05.442-.108.718-.178.282-.052.583-.195.919-.325.33-.15.714-.248 1.07-.487.359-.227.772-.416 1.137-.72.353-.314.78-.586 1.094-.984.343-.373.683-.764.947-1.21.305-.425.512-.892.73-1.353.199-.462.358-.934.488-1.392a12.58 12.58 0 0 0 .4-2.538c.036-.748.015-1.37-.029-1.82a7.897 7.897 0 0 0-.066-.56Z" fill="#828282"/></svg>',
            title: 'Цитата',
          },
        },
        image: {
          class: ImageTool,
          config: {
            field: 'file',
            // additionalRequestData: {
            //   width: 300,
            // },
            additionalRequestHeaders: {
              authorization: _context.$cookies.get('auth._token.local'),
            },
            endpoints: {
              byFile: isDev
                ? 'http://localhost:4001/file/upload/img'
                : 'https://api.megasreda.ru/file/upload/img',
            },
          },
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".509" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="M8.083 13.25a4.167 4.167 0 0 1 4.167-4.167h12.5a4.167 4.167 0 0 1 4.166 4.167v12.5a4.167 4.167 0 0 1-4.166 4.167h-12.5a4.167 4.167 0 0 1-4.167-4.167v-12.5Z" stroke="#828282" fill="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.854 18.458a2.604 2.604 0 1 0 0-5.208 2.604 2.604 0 0 0 0 5.208ZM21.131 20.147l-8.881 9.77h12.639a4.028 4.028 0 0 0 4.028-4.029v-.138c0-.485-.183-.672-.51-1.031l-4.199-4.578a2.083 2.083 0 0 0-3.077.006v0Z" stroke="#828282" stroke-width="2" fill="#ffffff" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            title: 'Изображение',
          },
        },
        delimiter: {
          class: Delimiter,
          toolbox: {
            icon: '<svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="39" height="39" rx="4.5" fill="#fff" stroke="#BDBDBD"/><path d="m10.303 19.4-2.95-.878.45-1.475 2.949 1.094-.088-3.36h1.494l-.098 3.408 2.9-1.074.45 1.485-2.998.888 1.934 2.647-1.211.918-1.817-2.813-1.757 2.744-1.221-.888 1.963-2.696Zm8.613 0-2.95-.878.45-1.475 2.95 1.094-.089-3.36h1.494l-.097 3.408 2.9-1.074.45 1.485-2.999.888 1.934 2.647-1.211.918-1.816-2.813-1.758 2.744-1.22-.888 1.962-2.696Zm8.613 0-2.949-.878.45-1.475 2.948 1.094-.087-3.36h1.494l-.098 3.408 2.9-1.074.45 1.485-2.998.888 1.933 2.647-1.21.918-1.817-2.813-1.758 2.744-1.22-.888 1.962-2.696Z" fill="#828282"/></svg>',
            title: 'Разделитель',
          },
        },
        // youtubeEmbed: {
        //   class: YoutubeEmbed,
        //   toolbox: {
        //     type: 'Insert',
        //   },
        // },
        // facebookEmbed: {
        //   class: FacebookEmbed,
        //   toolbox: {
        //     type: 'Insert',
        //   },
        // },
        // tiktokEmbed: {
        //   class: TikTokEmbed,
        //   toolbox: {
        //     type: 'Insert',
        //   },
        // },
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'noopener',
            availableTargets: ['_blank', '_self'],
            availableRels: ['noopener', 'nofollow', 'author', 'noreferrer'],
            validate: false,
          },
        },
        anyTuneName: {
          class: AlignmentTuneTool,
          config: {
            default: 'left',
            blocks: {
              header: 'left',
              list: 'left',
            },
          },
        },
      },
      i18n: {
        /**
         * @type {I18nDictionary}
         */
        messages: {
          /**
           * Other below: translation of different UI components of the editor.js core
           */
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': 'Нажмите, чтобы настроить',
                'or drag to move': 'или перетащите',
              },
            },
            inlineToolbar: {
              converter: {
                'Convert to': 'Конвертировать в',
              },
            },
            toolbar: {
              toolbox: {
                Add: 'Добавить',
              },
            },
          },

          /**
           * Section for translation Tool Names: both block and inline tools
           */
          toolNames: {
            Text: 'Параграф',
            Heading: 'Заголовок',
            List: 'Список',
            Warning: 'Примечание',
            Checklist: 'Чеклист',
            Quote: 'Цитата',
            Code: 'Код',
            Delimiter: 'Разделитель',
            'Raw HTML': 'HTML-фрагмент',
            ImageTool: 'Изображение',
            Table: 'Таблица',
            Link: 'Ссылка',
            Hyperlink: 'Ссылка',
            Marker: 'Маркер',
            Bold: 'Полужирный',
            Italic: 'Курсив',
            Underline: 'Подчёркнутый',
            Strikethrough: 'Зачёркнутый',
            InlineCode: 'Моноширинный',
          },

          /**
           * Section for passing translations to the external tools classes
           */
          tools: {
            /**
             * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
             * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
             */
            warning: {
              // <-- 'Warning' tool will accept this dictionary section
              Title: 'Название',
              Message: 'Сообщение',
            },
            embed: {
              'Enter a caption': 'Добавьте описание',
            },

            /**
             * Link is the internal Inline Tool
             */
            link: {
              'Add a link': 'Вставьте ссылку',
            },
            list: {
              Ordered: 'Нумерованный',
              Unordered: 'Маркированный',
            },
            hyperlink: {
              Save: 'Сохранить',
              'Select target': 'Где открывать',
              'Select rel': 'Связь',
            },
            image: {
              'Select an Image': 'Нажмите, чтобы выбрать файл',
              Caption: 'Подпись к изображению',
              'With border': 'Добавить рамку',
              'With background': 'Добавить фон',
              'Stretch image': 'На всю ширину',
            },
            table: {
              'Add column to left': 'Добавить столбец слева',
              'Add column to right': 'Добавить столбец справа',
              'Delete column': 'Удалить столбец',
              'Add row above': 'Добавить строку до',
              'Add row below': 'Добавить строку после',
              'Delete row': 'Удалить строку',
              'With headings': 'С заголовком',
              'Without headings': 'Без заголовка',
              Heading: 'Заголовок',
            },
            /**
             * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
             */
            stub: {
              'The block can not be displayed correctly.':
                'Блок не может быть отображен',
            },
          },

          /**
           * Section allows to translate Block Tunes
           */
          blockTunes: {
            /**
             * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
             * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
             *
             * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
             */
            delete: {
              Delete: 'Удалить',
            },
            moveUp: {
              'Move up': 'Переместить вверх',
            },
            moveDown: {
              'Move down': 'Переместить вниз',
            },
          },
        },
      },
      data: options.data || {},
      onChange(data) {
        options.onChange(data)
      },
    })
  }
  inject('editor', (options) => editor(options))
}
