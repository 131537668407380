import Vue from 'vue'
const listners = []
Vue.directive('scroll', {
  inserted(el, binding, vnode) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f, {
          passive: false,
        })
      }
    }
    window.addEventListener('scroll', f, { passive: false })
    listners.push({
      uid: vnode.context._uid,
      handler: f,
    })
  },

  unbind(_el, _binding, vnode) {
    if (listners !== 0) {
      listners
        .filter((el) => el.uid === vnode.context._uid)
        .forEach((listner, ind) => {
          window.removeEventListener('scroll', listner.handler, {
            passive: false,
          })
          listners.splice(ind, 1)
        })
    }
  },
})
