import Vue from 'vue'

/**
 * Фильтр для форматирования даты
 */
Vue.filter('formatDate', (value, now = String(new Date()), full = false) => {
  const dateTime = new Date(value)
  if (
    String(dateTime) === 'Invalid Date' ||
    String(new Date(now)) === 'Invalid Date'
  ) {
    return value
  }
  if (!full) {
    switch (new Date(value).setHours(0, 0, 0, 0)) {
      case diffDate():
        return todayFormat()
      case diffDate(1):
        return `вчера\u00A0в\u00A0${formatTime(dateTime)}`
      default:
        return `${formatDate(dateTime)},\u00A0${formatTime(dateTime)}`
    }
  } else {
    return `${formatDate(dateTime)},\u00A0${formatTime(dateTime)}`
  }

  function diffDate(i = 0) {
    return i === 0
      ? new Date(now).setHours(0, 0, 0, 0)
      : new Date(
          new Date(now).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24 * i
        ).setHours(0, 0, 0, 0)
  }

  function todayFormat() {
    const diff = new Date(now) - new Date(value)
    const sec = Math.floor(diff / 1000)
    const min = Math.floor(diff / 60000)
    const hour = Math.floor(diff / 3600000)

    switch (true) {
      case diff < 1000:
        return 'сейчас'

      case sec < 60:
        return `${sec}\u00A0${formatDateText(['секунду', 'секунды', 'секунд'])(
          sec
        )}\u00A0назад`

      case min < 60:
        return `${min}\u00A0${formatDateText(['минуту', 'минуты', 'минут'])(
          min
        )}\u00A0назад`

      case hour < 60:
        return `${hour}\u00A0${formatDateText(['час', 'часа', 'часов'])(
          hour
        )}\u00A0назад`
    }
  }

  function formatDate(date) {
    const dd = ('0' + String(date.getDate())).slice(-2)
    const mm = ('0' + String(date.getMonth() + 1)).slice(-2)
    const yy = ('0' + String(date.getFullYear() % 100)).slice(-2)

    return `${dd}.${mm}.${yy}`
  }

  function formatTime(date) {
    const hh = ('0' + String(date.getHours())).slice(-2)
    const mm = ('0' + String(date.getMinutes())).slice(-2)

    return `${hh}:${mm}`
  }

  function formatDateText(titles) {
    const cases = [2, 0, 1, 1, 1, 2]
    return function (number) {
      number = Math.abs(number)
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    }
  }
})
