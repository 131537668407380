export const state = () => ({
  isHeaderVisible: true,
  additionalMenu: null,
  isVisibleAdditionalMenu: false,
})

export const getters = {
  getVisible: (state) => {
    return state.isHeaderVisible
  },
  getAdditionalMenu: (state) => {
    return state.additionalMenu
  },
  getVisibleAdditionalMenu: (state) => {
    return state.isVisibleAdditionalMenu
  },
}

export const mutations = {
  SET_VISIBLE: (state, payload) => {
    state.isHeaderVisible = payload
  },
  TOGGLE_VISIBLE: (state) => {
    state.isHeaderVisible = !state.isVisible
  },
  SET_VISIBLE_ADDITIONAL_MENU: (state, payload) => {
    state.isVisibleAdditionalMenu = payload
  },
  SET_ADDITIONAL_MENU: (state, payload) => {
    state.additionalMenu = payload
  },
}
