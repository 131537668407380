
export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentError() {
      switch (this.error?.statusCode) {
        case 404:
          return 'PagesErrors404'

        default:
          return 'PagesErrors404'
      }
    },
  },
}
