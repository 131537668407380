import Vue from 'vue'

/**
 * Фильтр для форматирования даты
 */
Vue.filter('formatDateEnd', (value, now = String(new Date())) => {
  const dateTime = new Date(value)
  if (
    String(dateTime) === 'Invalid Date' ||
    String(new Date(now)) === 'Invalid Date'
  ) {
    return value
  }

  return `${formatDate(dateTime)}`
  // return `${formatDate(dateTime)},\u00A0${formatTime(dateTime)}`

  function formatDate(date) {
    const dd = ('0' + String(date.getDate())).slice(-2)
    const mm = ('0' + String(date.getMonth() + 1)).slice(-2)
    const yy = ('0' + String(date.getFullYear() % 100)).slice(-2)

    return `${dd}.${mm}.${yy}`
  }

  // function formatTime(date) {
  //   const hh = ('0' + String(date.getHours())).slice(-2)
  //   const mm = ('0' + String(date.getMinutes())).slice(-2)

  //   return `${hh}:${mm}`
  // }
})
