function authorName(user) {
  if (user.type === 'COMPANY') {
    return user.company?.title ? user.company.title : user.username
  } else if (user.profile?.firstName && user.profile?.lastName) {
    return `${user.profile.firstName} ${user.profile.lastName}`
  } else if (user.profile?.firstName) {
    return user.profile.firstName
  } else if (user.profile?.lastName) {
    return user.profile.lastName
  } else {
    return user.username
  }
}

export default function ({ $auth }, inject) {
  if (!$auth.loggedIn) {
    return
  }

  inject('user', {
    id: $auth.user.id,
    email: $auth.user.email,
    phone: $auth.user.phone,
    login: $auth.user.username,
    confirm: {
      email: $auth.user.confirm.email,
      phone: $auth.user.confirm.phone,
    },
    banned: $auth.user.banned.status,
    banReason: $auth.user.banned.reason,
    firstName: $auth.user.profile.firstName,
    lastName: $auth.user.profile.lastName,
    middleName: $auth.user.profile.middleName,
    description: $auth.user.profile.description,
    avatar: $auth.user.profile.avatar,
    website: $auth.user.profile.website,
    cover: $auth.user.profile.cover,
    color: $auth.user.profile.color,
    fullName: authorName($auth.user),
    isAdmin: !!$auth.user.roles.find((role) => role === 'ADMIN'),
    isExpert: !!$auth.user.roles.find((role) => role === 'EXPERT'),
    isUser: $auth.user.type === 'USER',
    isCompany: $auth.user.type === 'COMPANY',
    isModerator: !!$auth.user.roles.find((role) => role === 'MODERATOR'),
    isBusiness: !!$auth.user.roles.find((role) => role === 'BUSINESS'),
  })
}
