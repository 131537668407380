export default function ({ $axios, $auth, _redirect }) {
  // $axios.onRequest((config) => {
  //   console.log('Making request to ' + config.url)
  // })

  $axios.onError((error) => {
    if (error?.name === 'ExpiredAuthSessionError') {
      $auth.logout()
    }
    // const code = parseInt(error.response && error.response.status)
    // if (code === 400) {
    //   redirect('/400')
    // }
  })
}
