import Vue from 'vue'
import { VTooltip, VClosePopper } from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.directive('tooltip', VTooltip, {
  themes: {
    'verified-tooltip': {
      $extend: 'tooltip',
      triggers: ['hover', 'touch'],
      autoHide: true,
      placement: 'top-end',
    },
  },
})
Vue.directive('close-popover', VClosePopper)
