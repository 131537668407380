export const AsideVideo = () => import('../../components/Aside/Video.vue' /* webpackChunkName: "components/aside-video" */).then(c => wrapFunctional(c.default || c))
export const MegasredaWelcome = () => import('../../components/Megasreda/Welcome.vue' /* webpackChunkName: "components/megasreda-welcome" */).then(c => wrapFunctional(c.default || c))
export const BaseScroll = () => import('../../components/Base/Scroll.vue' /* webpackChunkName: "components/base-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseTypewriter = () => import('../../components/Base/Typewriter.vue' /* webpackChunkName: "components/base-typewriter" */).then(c => wrapFunctional(c.default || c))
export const AsideTelegramItem = () => import('../../components/Aside/Telegram/Item.vue' /* webpackChunkName: "components/aside-telegram-item" */).then(c => wrapFunctional(c.default || c))
export const AsideTelegram = () => import('../../components/Aside/Telegram/index.vue' /* webpackChunkName: "components/aside-telegram" */).then(c => wrapFunctional(c.default || c))
export const AsideTrendsItem = () => import('../../components/Aside/Trends/Item.vue' /* webpackChunkName: "components/aside-trends-item" */).then(c => wrapFunctional(c.default || c))
export const AsideTrends = () => import('../../components/Aside/Trends/index.vue' /* webpackChunkName: "components/aside-trends" */).then(c => wrapFunctional(c.default || c))
export const CoreIcon = () => import('../../components/Core/Icon/Icon.vue' /* webpackChunkName: "components/core-icon" */).then(c => wrapFunctional(c.default || c))
export const MegasredaBetaMessageBlock = () => import('../../components/Megasreda/BetaMessageBlock/BetaMessageBlock.vue' /* webpackChunkName: "components/megasreda-beta-message-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedBaseBlock = () => import('../../components/Megasreda/Recomended/BaseBlock.vue' /* webpackChunkName: "components/megasreda-recomended-base-block" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumImage = () => import('../../components/Megastudio/Auditorium/Image.vue' /* webpackChunkName: "components/megastudio-auditorium-image" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumLink = () => import('../../components/Megastudio/Auditorium/Link.vue' /* webpackChunkName: "components/megastudio-auditorium-link" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumMassMailing = () => import('../../components/Megastudio/Auditorium/MassMailing.vue' /* webpackChunkName: "components/megastudio-auditorium-mass-mailing" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumMenu = () => import('../../components/Megastudio/Auditorium/Menu.vue' /* webpackChunkName: "components/megastudio-auditorium-menu" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumNewsPost = () => import('../../components/Megastudio/Auditorium/NewsPost.vue' /* webpackChunkName: "components/megastudio-auditorium-news-post" */).then(c => wrapFunctional(c.default || c))
export const MegastudioAuditoriumSocialPost = () => import('../../components/Megastudio/Auditorium/SocialPost.vue' /* webpackChunkName: "components/megastudio-auditorium-social-post" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCollectionForm = () => import('../../components/Megastudio/Collection/Form.vue' /* webpackChunkName: "components/megastudio-collection-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsArticles = () => import('../../components/Megastudio/Competitors/Articles.vue' /* webpackChunkName: "components/megastudio-competitors-articles" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsEvents = () => import('../../components/Megastudio/Competitors/Events.vue' /* webpackChunkName: "components/megastudio-competitors-events" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsOffers = () => import('../../components/Megastudio/Competitors/Offers.vue' /* webpackChunkName: "components/megastudio-competitors-offers" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsProducts = () => import('../../components/Megastudio/Competitors/Products.vue' /* webpackChunkName: "components/megastudio-competitors-products" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsVacancys = () => import('../../components/Megastudio/Competitors/Vacancys.vue' /* webpackChunkName: "components/megastudio-competitors-vacancys" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCompetitorsVideo = () => import('../../components/Megastudio/Competitors/Video.vue' /* webpackChunkName: "components/megastudio-competitors-video" */).then(c => wrapFunctional(c.default || c))
export const MegastudioEvolutionSlider = () => import('../../components/Megastudio/Evolution/Slider.vue' /* webpackChunkName: "components/megastudio-evolution-slider" */).then(c => wrapFunctional(c.default || c))
export const MegastudioGroupsForm = () => import('../../components/Megastudio/Groups/Form.vue' /* webpackChunkName: "components/megastudio-groups-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioLayoutBase = () => import('../../components/Megastudio/Layout/Base.vue' /* webpackChunkName: "components/megastudio-layout-base" */).then(c => wrapFunctional(c.default || c))
export const MegastudioMenu = () => import('../../components/Megastudio/Menu/Menu.vue' /* webpackChunkName: "components/megastudio-menu" */).then(c => wrapFunctional(c.default || c))
export const MegastudioMenuAdmin = () => import('../../components/Megastudio/Menu/MenuAdmin.vue' /* webpackChunkName: "components/megastudio-menu-admin" */).then(c => wrapFunctional(c.default || c))
export const MegastudioMessageFreeAccount = () => import('../../components/Megastudio/Message/FreeAccount.vue' /* webpackChunkName: "components/megastudio-message-free-account" */).then(c => wrapFunctional(c.default || c))
export const MegastudioMessageFreeAccountCustom = () => import('../../components/Megastudio/Message/FreeAccountCustom.vue' /* webpackChunkName: "components/megastudio-message-free-account-custom" */).then(c => wrapFunctional(c.default || c))
export const MegastudioMessageVerified = () => import('../../components/Megastudio/Message/Verified.vue' /* webpackChunkName: "components/megastudio-message-verified" */).then(c => wrapFunctional(c.default || c))
export const MegastudioPulseTable = () => import('../../components/Megastudio/Pulse/Table.vue' /* webpackChunkName: "components/megastudio-pulse-table" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSectorForm = () => import('../../components/Megastudio/Sector/Form.vue' /* webpackChunkName: "components/megastudio-sector-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserArticles = () => import('../../components/Megastudio/User/Articles.vue' /* webpackChunkName: "components/megastudio-user-articles" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserCard = () => import('../../components/Megastudio/User/Card.vue' /* webpackChunkName: "components/megastudio-user-card" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserEvents = () => import('../../components/Megastudio/User/Events.vue' /* webpackChunkName: "components/megastudio-user-events" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserOffers = () => import('../../components/Megastudio/User/Offers.vue' /* webpackChunkName: "components/megastudio-user-offers" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserProducts = () => import('../../components/Megastudio/User/Products.vue' /* webpackChunkName: "components/megastudio-user-products" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserVacancys = () => import('../../components/Megastudio/User/Vacancys.vue' /* webpackChunkName: "components/megastudio-user-vacancys" */).then(c => wrapFunctional(c.default || c))
export const MegastudioUserVideos = () => import('../../components/Megastudio/User/Videos.vue' /* webpackChunkName: "components/megastudio-user-videos" */).then(c => wrapFunctional(c.default || c))
export const BaseCollectionsCard = () => import('../../components/Base/Collections/Card.vue' /* webpackChunkName: "components/base-collections-card" */).then(c => wrapFunctional(c.default || c))
export const BaseContent = () => import('../../components/Base/Content/Content.vue' /* webpackChunkName: "components/base-content" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentPreview = () => import('../../components/Base/Current/Preview.vue' /* webpackChunkName: "components/base-current-preview" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentSettings = () => import('../../components/Base/Current/Settings.vue' /* webpackChunkName: "components/base-current-settings" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentSettingsFull = () => import('../../components/Base/Current/SettingsFull.vue' /* webpackChunkName: "components/base-current-settings-full" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrent = () => import('../../components/Base/Current/index.vue' /* webpackChunkName: "components/base-current" */).then(c => wrapFunctional(c.default || c))
export const BaseEditor = () => import('../../components/Base/Editor/Editor.vue' /* webpackChunkName: "components/base-editor" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../components/Base/Footer/Footer.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseHeaderCollections = () => import('../../components/Base/Header/Collections.vue' /* webpackChunkName: "components/base-header-collections" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../components/Base/Header/Header.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../components/Base/Image/Image.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseLead = () => import('../../components/Base/Lead/Lead.vue' /* webpackChunkName: "components/base-lead" */).then(c => wrapFunctional(c.default || c))
export const BaseMarquee = () => import('../../components/Base/Marquee/Marquee.vue' /* webpackChunkName: "components/base-marquee" */).then(c => wrapFunctional(c.default || c))
export const BaseMarqueeSlide = () => import('../../components/Base/Marquee/Slide.vue' /* webpackChunkName: "components/base-marquee-slide" */).then(c => wrapFunctional(c.default || c))
export const BaseMenu = () => import('../../components/Base/Menu/Menu.vue' /* webpackChunkName: "components/base-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/Base/Modal/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/Base/Pagination/Pagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BasePdfToPdf = () => import('../../components/Base/Pdf/ToPdf.vue' /* webpackChunkName: "components/base-pdf-to-pdf" */).then(c => wrapFunctional(c.default || c))
export const BasePreloaderInit = () => import('../../components/Base/Preloader/Init.vue' /* webpackChunkName: "components/base-preloader-init" */).then(c => wrapFunctional(c.default || c))
export const BasePromoEvolution = () => import('../../components/Base/Promo/Evolution.vue' /* webpackChunkName: "components/base-promo-evolution" */).then(c => wrapFunctional(c.default || c))
export const BasePromoGoOut = () => import('../../components/Base/Promo/GoOut.vue' /* webpackChunkName: "components/base-promo-go-out" */).then(c => wrapFunctional(c.default || c))
export const BasePromoHeaderTop = () => import('../../components/Base/Promo/HeaderTop.vue' /* webpackChunkName: "components/base-promo-header-top" */).then(c => wrapFunctional(c.default || c))
export const BasePromoRegisterBottom = () => import('../../components/Base/Promo/RegisterBottom.vue' /* webpackChunkName: "components/base-promo-register-bottom" */).then(c => wrapFunctional(c.default || c))
export const BasePromoRegisterFeed = () => import('../../components/Base/Promo/RegisterFeed.vue' /* webpackChunkName: "components/base-promo-register-feed" */).then(c => wrapFunctional(c.default || c))
export const BasePromoRegisterInPost = () => import('../../components/Base/Promo/RegisterInPost.vue' /* webpackChunkName: "components/base-promo-register-in-post" */).then(c => wrapFunctional(c.default || c))
export const BasePromoRegisterInProduct = () => import('../../components/Base/Promo/RegisterInProduct.vue' /* webpackChunkName: "components/base-promo-register-in-product" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsNavigator = () => import('../../components/Base/Recommendations/Navigator.vue' /* webpackChunkName: "components/base-recommendations-navigator" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsSuppliers = () => import('../../components/Base/Recommendations/Suppliers.vue' /* webpackChunkName: "components/base-recommendations-suppliers" */).then(c => wrapFunctional(c.default || c))
export const BaseSkillButton = () => import('../../components/Base/Skill/Button.vue' /* webpackChunkName: "components/base-skill-button" */).then(c => wrapFunctional(c.default || c))
export const BaseSkillMixin = () => import('../../components/Base/Skill/mixin.vue' /* webpackChunkName: "components/base-skill-mixin" */).then(c => wrapFunctional(c.default || c))
export const BaseShareButton = () => import('../../components/Base/Share/Button.vue' /* webpackChunkName: "components/base-share-button" */).then(c => wrapFunctional(c.default || c))
export const BaseShareTelegram = () => import('../../components/Base/Share/Telegram.vue' /* webpackChunkName: "components/base-share-telegram" */).then(c => wrapFunctional(c.default || c))
export const BaseShareWhatsapp = () => import('../../components/Base/Share/Whatsapp.vue' /* webpackChunkName: "components/base-share-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionButton = () => import('../../components/Base/Subscription/Button.vue' /* webpackChunkName: "components/base-subscription-button" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionCheckbox = () => import('../../components/Base/Subscription/Checkbox.vue' /* webpackChunkName: "components/base-subscription-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionIcon = () => import('../../components/Base/Subscription/Icon.vue' /* webpackChunkName: "components/base-subscription-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionLink = () => import('../../components/Base/Subscription/Link.vue' /* webpackChunkName: "components/base-subscription-link" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionMixin = () => import('../../components/Base/Subscription/mixin.vue' /* webpackChunkName: "components/base-subscription-mixin" */).then(c => wrapFunctional(c.default || c))
export const BaseUserAvatar = () => import('../../components/Base/User/Avatar.vue' /* webpackChunkName: "components/base-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCard = () => import('../../components/Base/User/Card.vue' /* webpackChunkName: "components/base-user-card" */).then(c => wrapFunctional(c.default || c))
export const BaseUserLayout = () => import('../../components/Base/User/Layout.vue' /* webpackChunkName: "components/base-user-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseUserVerified = () => import('../../components/Base/User/Verified.vue' /* webpackChunkName: "components/base-user-verified" */).then(c => wrapFunctional(c.default || c))
export const BaseVideoPlayer = () => import('../../components/Base/Video/Player.vue' /* webpackChunkName: "components/base-video-player" */).then(c => wrapFunctional(c.default || c))
export const PagesCareerHeader = () => import('../../components/Pages/Career/Header.vue' /* webpackChunkName: "components/pages-career-header" */).then(c => wrapFunctional(c.default || c))
export const PagesCommunityVideo = () => import('../../components/Pages/Community/Video.vue' /* webpackChunkName: "components/pages-community-video" */).then(c => wrapFunctional(c.default || c))
export const PagesDraftsHeader = () => import('../../components/Pages/Drafts/Header.vue' /* webpackChunkName: "components/pages-drafts-header" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexEvents = () => import('../../components/Pages/Index/Events.vue' /* webpackChunkName: "components/pages-index-events" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexGoods = () => import('../../components/Pages/Index/Goods.vue' /* webpackChunkName: "components/pages-index-goods" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexNav = () => import('../../components/Pages/Index/Nav.vue' /* webpackChunkName: "components/pages-index-nav" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexOffers = () => import('../../components/Pages/Index/Offers.vue' /* webpackChunkName: "components/pages-index-offers" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexVideo = () => import('../../components/Pages/Index/Video.vue' /* webpackChunkName: "components/pages-index-video" */).then(c => wrapFunctional(c.default || c))
export const PagesMarketplaceCommunity = () => import('../../components/Pages/Marketplace/Community.vue' /* webpackChunkName: "components/pages-marketplace-community" */).then(c => wrapFunctional(c.default || c))
export const PagesMarketplaceSubscription = () => import('../../components/Pages/Marketplace/Subscription.vue' /* webpackChunkName: "components/pages-marketplace-subscription" */).then(c => wrapFunctional(c.default || c))
export const PagesMaterialHeader = () => import('../../components/Pages/Material/Header.vue' /* webpackChunkName: "components/pages-material-header" */).then(c => wrapFunctional(c.default || c))
export const PagesMediaCenterCommunity = () => import('../../components/Pages/MediaCenter/Community.vue' /* webpackChunkName: "components/pages-media-center-community" */).then(c => wrapFunctional(c.default || c))
export const PagesMediaCenterSubscription = () => import('../../components/Pages/MediaCenter/Subscription.vue' /* webpackChunkName: "components/pages-media-center-subscription" */).then(c => wrapFunctional(c.default || c))
export const PagesNavigatorHeader = () => import('../../components/Pages/Navigator/Header.vue' /* webpackChunkName: "components/pages-navigator-header" */).then(c => wrapFunctional(c.default || c))
export const PagesNavigatorProductHeader = () => import('../../components/Pages/Navigator/ProductHeader.vue' /* webpackChunkName: "components/pages-navigator-product-header" */).then(c => wrapFunctional(c.default || c))
export const PagesNavigatorStreamHeader = () => import('../../components/Pages/Navigator/StreamHeader.vue' /* webpackChunkName: "components/pages-navigator-stream-header" */).then(c => wrapFunctional(c.default || c))
export const PagesRecommendationsEvents = () => import('../../components/Pages/Recommendations/Events.vue' /* webpackChunkName: "components/pages-recommendations-events" */).then(c => wrapFunctional(c.default || c))
export const PagesRecommendationsGoods = () => import('../../components/Pages/Recommendations/Goods.vue' /* webpackChunkName: "components/pages-recommendations-goods" */).then(c => wrapFunctional(c.default || c))
export const PagesRecommendationsNav = () => import('../../components/Pages/Recommendations/Nav.vue' /* webpackChunkName: "components/pages-recommendations-nav" */).then(c => wrapFunctional(c.default || c))
export const PagesRecommendationsOffers = () => import('../../components/Pages/Recommendations/Offers.vue' /* webpackChunkName: "components/pages-recommendations-offers" */).then(c => wrapFunctional(c.default || c))
export const PagesRecommendationsVideo = () => import('../../components/Pages/Recommendations/Video.vue' /* webpackChunkName: "components/pages-recommendations-video" */).then(c => wrapFunctional(c.default || c))
export const PagesRegisterSteps = () => import('../../components/Pages/Register/Steps.vue' /* webpackChunkName: "components/pages-register-steps" */).then(c => wrapFunctional(c.default || c))
export const PagesStreamVideo = () => import('../../components/Pages/Stream/Video.vue' /* webpackChunkName: "components/pages-stream-video" */).then(c => wrapFunctional(c.default || c))
export const UiAsideCard = () => import('../../components/Ui/Aside/Card.vue' /* webpackChunkName: "components/ui-aside-card" */).then(c => wrapFunctional(c.default || c))
export const UiFeedCard = () => import('../../components/Ui/Feed/Card.vue' /* webpackChunkName: "components/ui-feed-card" */).then(c => wrapFunctional(c.default || c))
export const UiGridCard = () => import('../../components/Ui/Grid/Card.vue' /* webpackChunkName: "components/ui-grid-card" */).then(c => wrapFunctional(c.default || c))
export const CoreInputCheck = () => import('../../components/Core/Input/Check/Check.vue' /* webpackChunkName: "components/core-input-check" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsAdd = () => import('../../components/Core/Icon/Icons/Add.vue' /* webpackChunkName: "components/core-icon-icons-add" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsAlignJustify = () => import('../../components/Core/Icon/Icons/AlignJustify.vue' /* webpackChunkName: "components/core-icon-icons-align-justify" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsAngleLeft = () => import('../../components/Core/Icon/Icons/AngleLeft.vue' /* webpackChunkName: "components/core-icon-icons-angle-left" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsAngleRight = () => import('../../components/Core/Icon/Icons/AngleRight.vue' /* webpackChunkName: "components/core-icon-icons-angle-right" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsApps = () => import('../../components/Core/Icon/Icons/Apps.vue' /* webpackChunkName: "components/core-icon-icons-apps" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsArrowDown = () => import('../../components/Core/Icon/Icons/ArrowDown.vue' /* webpackChunkName: "components/core-icon-icons-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsArrowUpSmall = () => import('../../components/Core/Icon/Icons/ArrowUpSmall.vue' /* webpackChunkName: "components/core-icon-icons-arrow-up-small" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsArrows = () => import('../../components/Core/Icon/Icons/Arrows.vue' /* webpackChunkName: "components/core-icon-icons-arrows" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBan = () => import('../../components/Core/Icon/Icons/Ban.vue' /* webpackChunkName: "components/core-icon-icons-ban" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBookmark = () => import('../../components/Core/Icon/Icons/Bookmark.vue' /* webpackChunkName: "components/core-icon-icons-bookmark" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBox = () => import('../../components/Core/Icon/Icons/Box.vue' /* webpackChunkName: "components/core-icon-icons-box" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBriefcase = () => import('../../components/Core/Icon/Icons/Briefcase.vue' /* webpackChunkName: "components/core-icon-icons-briefcase" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBroom = () => import('../../components/Core/Icon/Icons/Broom.vue' /* webpackChunkName: "components/core-icon-icons-broom" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBuilding = () => import('../../components/Core/Icon/Icons/Building.vue' /* webpackChunkName: "components/core-icon-icons-building" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBulb = () => import('../../components/Core/Icon/Icons/Bulb.vue' /* webpackChunkName: "components/core-icon-icons-bulb" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBurger = () => import('../../components/Core/Icon/Icons/Burger.vue' /* webpackChunkName: "components/core-icon-icons-burger" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsBusinessAccount = () => import('../../components/Core/Icon/Icons/BusinessAccount.vue' /* webpackChunkName: "components/core-icon-icons-business-account" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCalendar = () => import('../../components/Core/Icon/Icons/Calendar.vue' /* webpackChunkName: "components/core-icon-icons-calendar" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCamera = () => import('../../components/Core/Icon/Icons/Camera.vue' /* webpackChunkName: "components/core-icon-icons-camera" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCap = () => import('../../components/Core/Icon/Icons/Cap.vue' /* webpackChunkName: "components/core-icon-icons-cap" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsChartHistogram = () => import('../../components/Core/Icon/Icons/ChartHistogram.vue' /* webpackChunkName: "components/core-icon-icons-chart-histogram" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCheck = () => import('../../components/Core/Icon/Icons/Check.vue' /* webpackChunkName: "components/core-icon-icons-check" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCheckBox = () => import('../../components/Core/Icon/Icons/CheckBox.vue' /* webpackChunkName: "components/core-icon-icons-check-box" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsChecked = () => import('../../components/Core/Icon/Icons/Checked.vue' /* webpackChunkName: "components/core-icon-icons-checked" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsClose = () => import('../../components/Core/Icon/Icons/Close.vue' /* webpackChunkName: "components/core-icon-icons-close" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCommentUser = () => import('../../components/Core/Icon/Icons/CommentUser.vue' /* webpackChunkName: "components/core-icon-icons-comment-user" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsComputer = () => import('../../components/Core/Icon/Icons/Computer.vue' /* webpackChunkName: "components/core-icon-icons-computer" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCopy = () => import('../../components/Core/Icon/Icons/Copy.vue' /* webpackChunkName: "components/core-icon-icons-copy" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCross = () => import('../../components/Core/Icon/Icons/Cross.vue' /* webpackChunkName: "components/core-icon-icons-cross" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCrossSmall = () => import('../../components/Core/Icon/Icons/CrossSmall.vue' /* webpackChunkName: "components/core-icon-icons-cross-small" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsCrown = () => import('../../components/Core/Icon/Icons/Crown.vue' /* webpackChunkName: "components/core-icon-icons-crown" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsDots = () => import('../../components/Core/Icon/Icons/Dots.vue' /* webpackChunkName: "components/core-icon-icons-dots" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsDownload = () => import('../../components/Core/Icon/Icons/Download.vue' /* webpackChunkName: "components/core-icon-icons-download" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsEdit = () => import('../../components/Core/Icon/Icons/Edit.vue' /* webpackChunkName: "components/core-icon-icons-edit" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsEditFull = () => import('../../components/Core/Icon/Icons/EditFull.vue' /* webpackChunkName: "components/core-icon-icons-edit-full" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsEmail = () => import('../../components/Core/Icon/Icons/Email.vue' /* webpackChunkName: "components/core-icon-icons-email" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsExclamation = () => import('../../components/Core/Icon/Icons/Exclamation.vue' /* webpackChunkName: "components/core-icon-icons-exclamation" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsExpand = () => import('../../components/Core/Icon/Icons/Expand.vue' /* webpackChunkName: "components/core-icon-icons-expand" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsEye = () => import('../../components/Core/Icon/Icons/Eye.vue' /* webpackChunkName: "components/core-icon-icons-eye" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsEyeCrossed = () => import('../../components/Core/Icon/Icons/EyeCrossed.vue' /* webpackChunkName: "components/core-icon-icons-eye-crossed" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFacebook = () => import('../../components/Core/Icon/Icons/Facebook.vue' /* webpackChunkName: "components/core-icon-icons-facebook" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFlag = () => import('../../components/Core/Icon/Icons/Flag.vue' /* webpackChunkName: "components/core-icon-icons-flag" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFlame = () => import('../../components/Core/Icon/Icons/Flame.vue' /* webpackChunkName: "components/core-icon-icons-flame" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFlameGradient = () => import('../../components/Core/Icon/Icons/FlameGradient.vue' /* webpackChunkName: "components/core-icon-icons-flame-gradient" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFollowing = () => import('../../components/Core/Icon/Icons/Following.vue' /* webpackChunkName: "components/core-icon-icons-following" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsFox = () => import('../../components/Core/Icon/Icons/Fox.vue' /* webpackChunkName: "components/core-icon-icons-fox" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsHome = () => import('../../components/Core/Icon/Icons/Home.vue' /* webpackChunkName: "components/core-icon-icons-home" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsIncognito = () => import('../../components/Core/Icon/Icons/Incognito.vue' /* webpackChunkName: "components/core-icon-icons-incognito" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsInfinity = () => import('../../components/Core/Icon/Icons/Infinity.vue' /* webpackChunkName: "components/core-icon-icons-infinity" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsInfo = () => import('../../components/Core/Icon/Icons/Info.vue' /* webpackChunkName: "components/core-icon-icons-info" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsInstagram = () => import('../../components/Core/Icon/Icons/Instagram.vue' /* webpackChunkName: "components/core-icon-icons-instagram" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsInterrogation = () => import('../../components/Core/Icon/Icons/Interrogation.vue' /* webpackChunkName: "components/core-icon-icons-interrogation" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLaptop = () => import('../../components/Core/Icon/Icons/Laptop.vue' /* webpackChunkName: "components/core-icon-icons-laptop" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLaugh = () => import('../../components/Core/Icon/Icons/Laugh.vue' /* webpackChunkName: "components/core-icon-icons-laugh" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLayers = () => import('../../components/Core/Icon/Icons/Layers.vue' /* webpackChunkName: "components/core-icon-icons-layers" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLearning = () => import('../../components/Core/Icon/Icons/Learning.vue' /* webpackChunkName: "components/core-icon-icons-learning" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLetterCase = () => import('../../components/Core/Icon/Icons/LetterCase.vue' /* webpackChunkName: "components/core-icon-icons-letter-case" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLike = () => import('../../components/Core/Icon/Icons/Like.vue' /* webpackChunkName: "components/core-icon-icons-like" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLink = () => import('../../components/Core/Icon/Icons/Link.vue' /* webpackChunkName: "components/core-icon-icons-link" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLinkAway = () => import('../../components/Core/Icon/Icons/LinkAway.vue' /* webpackChunkName: "components/core-icon-icons-link-away" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsList = () => import('../../components/Core/Icon/Icons/List.vue' /* webpackChunkName: "components/core-icon-icons-list" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsListCheck = () => import('../../components/Core/Icon/Icons/ListCheck.vue' /* webpackChunkName: "components/core-icon-icons-list-check" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsLock = () => import('../../components/Core/Icon/Icons/Lock.vue' /* webpackChunkName: "components/core-icon-icons-lock" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMagicWand = () => import('../../components/Core/Icon/Icons/MagicWand.vue' /* webpackChunkName: "components/core-icon-icons-magic-wand" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMegaphone = () => import('../../components/Core/Icon/Icons/Megaphone.vue' /* webpackChunkName: "components/core-icon-icons-megaphone" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMegasredaLogo = () => import('../../components/Core/Icon/Icons/MegasredaLogo.vue' /* webpackChunkName: "components/core-icon-icons-megasreda-logo" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMegasredaLogoOld = () => import('../../components/Core/Icon/Icons/MegasredaLogoOld.vue' /* webpackChunkName: "components/core-icon-icons-megasreda-logo-old" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMegasredaNoFill = () => import('../../components/Core/Icon/Icons/MegasredaNoFill.vue' /* webpackChunkName: "components/core-icon-icons-megasreda-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMegasredaWhite = () => import('../../components/Core/Icon/Icons/MegasredaWhite.vue' /* webpackChunkName: "components/core-icon-icons-megasreda-white" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsModeratorAccount = () => import('../../components/Core/Icon/Icons/ModeratorAccount.vue' /* webpackChunkName: "components/core-icon-icons-moderator-account" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsMountains = () => import('../../components/Core/Icon/Icons/Mountains.vue' /* webpackChunkName: "components/core-icon-icons-mountains" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigation = () => import('../../components/Core/Icon/Icons/Navigation.vue' /* webpackChunkName: "components/core-icon-icons-navigation" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorAuthors = () => import('../../components/Core/Icon/Icons/NavigatorAuthors.vue' /* webpackChunkName: "components/core-icon-icons-navigator-authors" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorBroadcasts = () => import('../../components/Core/Icon/Icons/NavigatorBroadcasts.vue' /* webpackChunkName: "components/core-icon-icons-navigator-broadcasts" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorCompanys = () => import('../../components/Core/Icon/Icons/NavigatorCompanys.vue' /* webpackChunkName: "components/core-icon-icons-navigator-companys" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorEvents = () => import('../../components/Core/Icon/Icons/NavigatorEvents.vue' /* webpackChunkName: "components/core-icon-icons-navigator-events" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorGoods = () => import('../../components/Core/Icon/Icons/NavigatorGoods.vue' /* webpackChunkName: "components/core-icon-icons-navigator-goods" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorLectures = () => import('../../components/Core/Icon/Icons/NavigatorLectures.vue' /* webpackChunkName: "components/core-icon-icons-navigator-lectures" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorMore = () => import('../../components/Core/Icon/Icons/NavigatorMore.vue' /* webpackChunkName: "components/core-icon-icons-navigator-more" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorOffers = () => import('../../components/Core/Icon/Icons/NavigatorOffers.vue' /* webpackChunkName: "components/core-icon-icons-navigator-offers" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorQuestions = () => import('../../components/Core/Icon/Icons/NavigatorQuestions.vue' /* webpackChunkName: "components/core-icon-icons-navigator-questions" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorReviews = () => import('../../components/Core/Icon/Icons/NavigatorReviews.vue' /* webpackChunkName: "components/core-icon-icons-navigator-reviews" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorStreams = () => import('../../components/Core/Icon/Icons/NavigatorStreams.vue' /* webpackChunkName: "components/core-icon-icons-navigator-streams" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsNavigatorVacancies = () => import('../../components/Core/Icon/Icons/NavigatorVacancies.vue' /* webpackChunkName: "components/core-icon-icons-navigator-vacancies" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsOctogram = () => import('../../components/Core/Icon/Icons/Octogram.vue' /* webpackChunkName: "components/core-icon-icons-octogram" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsOk = () => import('../../components/Core/Icon/Icons/Ok.vue' /* webpackChunkName: "components/core-icon-icons-ok" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsOkNoFill = () => import('../../components/Core/Icon/Icons/OkNoFill.vue' /* webpackChunkName: "components/core-icon-icons-ok-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPaperPlane = () => import('../../components/Core/Icon/Icons/PaperPlane.vue' /* webpackChunkName: "components/core-icon-icons-paper-plane" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPdf = () => import('../../components/Core/Icon/Icons/Pdf.vue' /* webpackChunkName: "components/core-icon-icons-pdf" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPencil = () => import('../../components/Core/Icon/Icons/Pencil.vue' /* webpackChunkName: "components/core-icon-icons-pencil" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPicture = () => import('../../components/Core/Icon/Icons/Picture.vue' /* webpackChunkName: "components/core-icon-icons-picture" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPlay = () => import('../../components/Core/Icon/Icons/Play.vue' /* webpackChunkName: "components/core-icon-icons-play" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPlayAlt = () => import('../../components/Core/Icon/Icons/PlayAlt.vue' /* webpackChunkName: "components/core-icon-icons-play-alt" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsPreload = () => import('../../components/Core/Icon/Icons/Preload.vue' /* webpackChunkName: "components/core-icon-icons-preload" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsQuote = () => import('../../components/Core/Icon/Icons/Quote.vue' /* webpackChunkName: "components/core-icon-icons-quote" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsRedo = () => import('../../components/Core/Icon/Icons/Redo.vue' /* webpackChunkName: "components/core-icon-icons-redo" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsRocket = () => import('../../components/Core/Icon/Icons/Rocket.vue' /* webpackChunkName: "components/core-icon-icons-rocket" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsRouble = () => import('../../components/Core/Icon/Icons/Rouble.vue' /* webpackChunkName: "components/core-icon-icons-rouble" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSave = () => import('../../components/Core/Icon/Icons/Save.vue' /* webpackChunkName: "components/core-icon-icons-save" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSearch = () => import('../../components/Core/Icon/Icons/Search.vue' /* webpackChunkName: "components/core-icon-icons-search" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSettings = () => import('../../components/Core/Icon/Icons/Settings.vue' /* webpackChunkName: "components/core-icon-icons-settings" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSettingsSliders = () => import('../../components/Core/Icon/Icons/SettingsSliders.vue' /* webpackChunkName: "components/core-icon-icons-settings-sliders" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsShare = () => import('../../components/Core/Icon/Icons/Share.vue' /* webpackChunkName: "components/core-icon-icons-share" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsShareLink = () => import('../../components/Core/Icon/Icons/ShareLink.vue' /* webpackChunkName: "components/core-icon-icons-share-link" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSheets = () => import('../../components/Core/Icon/Icons/Sheets.vue' /* webpackChunkName: "components/core-icon-icons-sheets" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsShieldCheck = () => import('../../components/Core/Icon/Icons/ShieldCheck.vue' /* webpackChunkName: "components/core-icon-icons-shield-check" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsShop = () => import('../../components/Core/Icon/Icons/Shop.vue' /* webpackChunkName: "components/core-icon-icons-shop" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSignIn = () => import('../../components/Core/Icon/Icons/SignIn.vue' /* webpackChunkName: "components/core-icon-icons-sign-in" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSignOut = () => import('../../components/Core/Icon/Icons/SignOut.vue' /* webpackChunkName: "components/core-icon-icons-sign-out" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsSmile = () => import('../../components/Core/Icon/Icons/Smile.vue' /* webpackChunkName: "components/core-icon-icons-smile" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsStar = () => import('../../components/Core/Icon/Icons/Star.vue' /* webpackChunkName: "components/core-icon-icons-star" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsStats = () => import('../../components/Core/Icon/Icons/Stats.vue' /* webpackChunkName: "components/core-icon-icons-stats" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsStreams = () => import('../../components/Core/Icon/Icons/Streams.vue' /* webpackChunkName: "components/core-icon-icons-streams" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTelegram = () => import('../../components/Core/Icon/Icons/Telegram.vue' /* webpackChunkName: "components/core-icon-icons-telegram" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTelegramBtn = () => import('../../components/Core/Icon/Icons/TelegramBtn.vue' /* webpackChunkName: "components/core-icon-icons-telegram-btn" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTelegramLight = () => import('../../components/Core/Icon/Icons/TelegramLight.vue' /* webpackChunkName: "components/core-icon-icons-telegram-light" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTelegramNoFill = () => import('../../components/Core/Icon/Icons/TelegramNoFill.vue' /* webpackChunkName: "components/core-icon-icons-telegram-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTeletype = () => import('../../components/Core/Icon/Icons/Teletype.vue' /* webpackChunkName: "components/core-icon-icons-teletype" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTeletypeNoFill = () => import('../../components/Core/Icon/Icons/TeletypeNoFill.vue' /* webpackChunkName: "components/core-icon-icons-teletype-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsText = () => import('../../components/Core/Icon/Icons/Text.vue' /* webpackChunkName: "components/core-icon-icons-text" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTextCheck = () => import('../../components/Core/Icon/Icons/TextCheck.vue' /* webpackChunkName: "components/core-icon-icons-text-check" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsThumbtack = () => import('../../components/Core/Icon/Icons/Thumbtack.vue' /* webpackChunkName: "components/core-icon-icons-thumbtack" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTransform = () => import('../../components/Core/Icon/Icons/Transform.vue' /* webpackChunkName: "components/core-icon-icons-transform" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTrash = () => import('../../components/Core/Icon/Icons/Trash.vue' /* webpackChunkName: "components/core-icon-icons-trash" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsTwitter = () => import('../../components/Core/Icon/Icons/Twitter.vue' /* webpackChunkName: "components/core-icon-icons-twitter" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsUnLock = () => import('../../components/Core/Icon/Icons/UnLock.vue' /* webpackChunkName: "components/core-icon-icons-un-lock" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsUserAdd = () => import('../../components/Core/Icon/Icons/UserAdd.vue' /* webpackChunkName: "components/core-icon-icons-user-add" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsUserFollowing = () => import('../../components/Core/Icon/Icons/UserFollowing.vue' /* webpackChunkName: "components/core-icon-icons-user-following" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsUsers = () => import('../../components/Core/Icon/Icons/Users.vue' /* webpackChunkName: "components/core-icon-icons-users" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsVerification = () => import('../../components/Core/Icon/Icons/Verification.vue' /* webpackChunkName: "components/core-icon-icons-verification" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsVk = () => import('../../components/Core/Icon/Icons/Vk.vue' /* webpackChunkName: "components/core-icon-icons-vk" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsVkNoFill = () => import('../../components/Core/Icon/Icons/VkNoFill.vue' /* webpackChunkName: "components/core-icon-icons-vk-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsVkRounded = () => import('../../components/Core/Icon/Icons/VkRounded.vue' /* webpackChunkName: "components/core-icon-icons-vk-rounded" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsWhatsapp = () => import('../../components/Core/Icon/Icons/Whatsapp.vue' /* webpackChunkName: "components/core-icon-icons-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsWhatsappLight = () => import('../../components/Core/Icon/Icons/WhatsappLight.vue' /* webpackChunkName: "components/core-icon-icons-whatsapp-light" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsYandexRounded = () => import('../../components/Core/Icon/Icons/YandexRounded.vue' /* webpackChunkName: "components/core-icon-icons-yandex-rounded" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsYandexZen = () => import('../../components/Core/Icon/Icons/YandexZen.vue' /* webpackChunkName: "components/core-icon-icons-yandex-zen" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsYandexZenNoFill = () => import('../../components/Core/Icon/Icons/YandexZenNoFill.vue' /* webpackChunkName: "components/core-icon-icons-yandex-zen-no-fill" */).then(c => wrapFunctional(c.default || c))
export const CoreIconIconsYoutube = () => import('../../components/Core/Icon/Icons/Youtube.vue' /* webpackChunkName: "components/core-icon-icons-youtube" */).then(c => wrapFunctional(c.default || c))
export const CoreInputCode = () => import('../../components/Core/Input/Code/Code.vue' /* webpackChunkName: "components/core-input-code" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelect = () => import('../../components/Core/Input/Select/Select.vue' /* webpackChunkName: "components/core-input-select" */).then(c => wrapFunctional(c.default || c))
export const CoreInputText = () => import('../../components/Core/Input/Text/Text.vue' /* webpackChunkName: "components/core-input-text" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextarea = () => import('../../components/Core/Input/Textarea/Textarea.vue' /* webpackChunkName: "components/core-input-textarea" */).then(c => wrapFunctional(c.default || c))
export const CoreInputToggle = () => import('../../components/Core/Input/Toggle/Toggle.vue' /* webpackChunkName: "components/core-input-toggle" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAdvertusementWidgetsRecomendedBroadcasts = () => import('../../components/Megasreda/Advertusement/Widgets/RecomendedBroadcasts.vue' /* webpackChunkName: "components/megasreda-advertusement-widgets-recomended-broadcasts" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAdvertusementWidgetsRecomendedEvents = () => import('../../components/Megasreda/Advertusement/Widgets/RecomendedEvents.vue' /* webpackChunkName: "components/megasreda-advertusement-widgets-recomended-events" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAdvertusementWidgetsRecomendedGoods = () => import('../../components/Megasreda/Advertusement/Widgets/RecomendedGoods.vue' /* webpackChunkName: "components/megasreda-advertusement-widgets-recomended-goods" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAdvertusementWidgetsRecomendedOffers = () => import('../../components/Megasreda/Advertusement/Widgets/RecomendedOffers.vue' /* webpackChunkName: "components/megasreda-advertusement-widgets-recomended-offers" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAdvertusementWidgetsRecomendedVacancys = () => import('../../components/Megasreda/Advertusement/Widgets/RecomendedVacancys.vue' /* webpackChunkName: "components/megasreda-advertusement-widgets-recomended-vacancys" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentBroadcastFeedCard = () => import('../../components/Megasreda/Content/Broadcast/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-broadcast-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentBroadcastFeedCardDraft = () => import('../../components/Megasreda/Content/Broadcast/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-broadcast-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentBroadcastFeedCardSlide = () => import('../../components/Megasreda/Content/Broadcast/FeedCardSlide.vue' /* webpackChunkName: "components/megasreda-content-broadcast-feed-card-slide" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentBroadcastUserMenu = () => import('../../components/Megasreda/Content/Broadcast/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-broadcast-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentLayoutBase = () => import('../../components/Megasreda/Content/Layout/Base.vue' /* webpackChunkName: "components/megasreda-content-layout-base" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentLayoutFull = () => import('../../components/Megasreda/Content/Layout/Full.vue' /* webpackChunkName: "components/megasreda-content-layout-full" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsCreate = () => import('../../components/Megasreda/Content/Recommendations/Create.vue' /* webpackChunkName: "components/megasreda-content-recommendations-create" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsEdit = () => import('../../components/Megasreda/Content/Recommendations/Edit.vue' /* webpackChunkName: "components/megasreda-content-recommendations-edit" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsFeedCard = () => import('../../components/Megasreda/Content/Recommendations/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-recommendations-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsPreview = () => import('../../components/Megasreda/Content/Recommendations/Preview.vue' /* webpackChunkName: "components/megasreda-content-recommendations-preview" */).then(c => wrapFunctional(c.default || c))
export const MegasredaPlaceMenu = () => import('../../components/Megasreda/Place/Menu/Menu.vue' /* webpackChunkName: "components/megasreda-place-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlock = () => import('../../components/Megasreda/Recomended/Author/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedSocialInArticle = () => import('../../components/Megasreda/Recomended/Social/InArticle.vue' /* webpackChunkName: "components/megasreda-recomended-social-in-article" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedSocial = () => import('../../components/Megasreda/Recomended/Social/Social.vue' /* webpackChunkName: "components/megasreda-recomended-social" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCollectionListItem = () => import('../../components/Megastudio/Collection/List/Item.vue' /* webpackChunkName: "components/megastudio-collection-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCollectionList = () => import('../../components/Megastudio/Collection/List/index.vue' /* webpackChunkName: "components/megastudio-collection-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioCollectionUnallocatedStreams = () => import('../../components/Megastudio/Collection/UnallocatedStreams/index.vue' /* webpackChunkName: "components/megastudio-collection-unallocated-streams" */).then(c => wrapFunctional(c.default || c))
export const MegastudioEvolutionListItem = () => import('../../components/Megastudio/Evolution/List/Item.vue' /* webpackChunkName: "components/megastudio-evolution-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioEvolutionList = () => import('../../components/Megastudio/Evolution/List/List.vue' /* webpackChunkName: "components/megastudio-evolution-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioGroupsGroupListItem = () => import('../../components/Megastudio/Groups/GroupList/Item.vue' /* webpackChunkName: "components/megastudio-groups-group-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioGroupsGroupList = () => import('../../components/Megastudio/Groups/GroupList/index.vue' /* webpackChunkName: "components/megastudio-groups-group-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioProductCard = () => import('../../components/Megastudio/Product/Card/Card.vue' /* webpackChunkName: "components/megastudio-product-card" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSectorSectorListItem = () => import('../../components/Megastudio/Sector/SectorList/Item.vue' /* webpackChunkName: "components/megastudio-sector-sector-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSectorSectorList = () => import('../../components/Megastudio/Sector/SectorList/index.vue' /* webpackChunkName: "components/megastudio-sector-sector-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioGroupsUnallocatedProducts = () => import('../../components/Megastudio/Groups/UnallocatedProducts/index.vue' /* webpackChunkName: "components/megastudio-groups-unallocated-products" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSectorUnallocatedCompanies = () => import('../../components/Megastudio/Sector/UnallocatedCompanies/index.vue' /* webpackChunkName: "components/megastudio-sector-unallocated-companies" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsTariffsForm = () => import('../../components/Megastudio/Settings/Tariffs/Form.vue' /* webpackChunkName: "components/megastudio-settings-tariffs-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioStreamImages = () => import('../../components/Megastudio/Stream/Images/Images.vue' /* webpackChunkName: "components/megastudio-stream-images" */).then(c => wrapFunctional(c.default || c))
export const BaseCollectionsList = () => import('../../components/Base/Collections/List/index.vue' /* webpackChunkName: "components/base-collections-list" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentListItem = () => import('../../components/Base/Current/List/Item.vue' /* webpackChunkName: "components/base-current-list-item" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentList = () => import('../../components/Base/Current/List/index.vue' /* webpackChunkName: "components/base-current-list" */).then(c => wrapFunctional(c.default || c))
export const BaseContentBlock = () => import('../../components/Base/Content/Block/Block.vue' /* webpackChunkName: "components/base-content-block" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentTypesCommunity = () => import('../../components/Base/Current/Types/Community.vue' /* webpackChunkName: "components/base-current-types-community" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentTypesDefault = () => import('../../components/Base/Current/Types/Default.vue' /* webpackChunkName: "components/base-current-types-default" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentTypesGrid = () => import('../../components/Base/Current/Types/Grid.vue' /* webpackChunkName: "components/base-current-types-grid" */).then(c => wrapFunctional(c.default || c))
export const BaseCurrentTypesStream = () => import('../../components/Base/Current/Types/Stream.vue' /* webpackChunkName: "components/base-current-types-stream" */).then(c => wrapFunctional(c.default || c))
export const BaseHeaderLogo = () => import('../../components/Base/Header/Logo/Logo.vue' /* webpackChunkName: "components/base-header-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseHeaderUserNav = () => import('../../components/Base/Header/UserNav/UserNav.vue' /* webpackChunkName: "components/base-header-user-nav" */).then(c => wrapFunctional(c.default || c))
export const BaseLeadTypeDefault = () => import('../../components/Base/Lead/Type/Default.vue' /* webpackChunkName: "components/base-lead-type-default" */).then(c => wrapFunctional(c.default || c))
export const BaseLeadTypeProduct = () => import('../../components/Base/Lead/Type/Product.vue' /* webpackChunkName: "components/base-lead-type-product" */).then(c => wrapFunctional(c.default || c))
export const BaseMenuItem = () => import('../../components/Base/Menu/Item/index.vue' /* webpackChunkName: "components/base-menu-item" */).then(c => wrapFunctional(c.default || c))
export const BaseModalConfirm = () => import('../../components/Base/Modal/Confirm/Confirm.vue' /* webpackChunkName: "components/base-modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorCompanyCompanysList = () => import('../../components/Base/Navigator/Company/CompanysList.vue' /* webpackChunkName: "components/base-navigator-company-companys-list" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorProductProductsList = () => import('../../components/Base/Navigator/Product/ProductsList.vue' /* webpackChunkName: "components/base-navigator-product-products-list" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorStreamStreamsList = () => import('../../components/Base/Navigator/Stream/StreamsList.vue' /* webpackChunkName: "components/base-navigator-stream-streams-list" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorUserUsersList = () => import('../../components/Base/Navigator/User/UsersList.vue' /* webpackChunkName: "components/base-navigator-user-users-list" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorBroadcast = () => import('../../components/Base/Profile/Author/Broadcast.vue' /* webpackChunkName: "components/base-profile-author-broadcast" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCompetitors = () => import('../../components/Base/Profile/Author/Competitors.vue' /* webpackChunkName: "components/base-profile-author-competitors" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorContacts = () => import('../../components/Base/Profile/Author/Contacts.vue' /* webpackChunkName: "components/base-profile-author-contacts" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorDescription = () => import('../../components/Base/Profile/Author/Description.vue' /* webpackChunkName: "components/base-profile-author-description" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorExclusive = () => import('../../components/Base/Profile/Author/Exclusive.vue' /* webpackChunkName: "components/base-profile-author-exclusive" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorHeader = () => import('../../components/Base/Profile/Author/Header.vue' /* webpackChunkName: "components/base-profile-author-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorInfoCard = () => import('../../components/Base/Profile/Author/InfoCard.vue' /* webpackChunkName: "components/base-profile-author-info-card" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLayout = () => import('../../components/Base/Profile/Author/Layout.vue' /* webpackChunkName: "components/base-profile-author-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorOffers = () => import('../../components/Base/Profile/Author/Offers.vue' /* webpackChunkName: "components/base-profile-author-offers" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorRatings = () => import('../../components/Base/Profile/Author/Ratings.vue' /* webpackChunkName: "components/base-profile-author-ratings" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorUserMenu = () => import('../../components/Base/Profile/Author/UserMenu.vue' /* webpackChunkName: "components/base-profile-author-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorVideo = () => import('../../components/Base/Profile/Author/Video.vue' /* webpackChunkName: "components/base-profile-author-video" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileBroadcastLayout = () => import('../../components/Base/Profile/Broadcast/Layout.vue' /* webpackChunkName: "components/base-profile-broadcast-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileBroadcastsHeader = () => import('../../components/Base/Profile/Broadcasts/Header.vue' /* webpackChunkName: "components/base-profile-broadcasts-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileBroadcastsLayout = () => import('../../components/Base/Profile/Broadcasts/Layout.vue' /* webpackChunkName: "components/base-profile-broadcasts-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityBroadcast = () => import('../../components/Base/Profile/Community/Broadcast.vue' /* webpackChunkName: "components/base-profile-community-broadcast" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityHeader = () => import('../../components/Base/Profile/Community/Header.vue' /* webpackChunkName: "components/base-profile-community-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityLayout = () => import('../../components/Base/Profile/Community/Layout.vue' /* webpackChunkName: "components/base-profile-community-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductDescription = () => import('../../components/Base/Profile/Product/Description.vue' /* webpackChunkName: "components/base-profile-product-description" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductHeader = () => import('../../components/Base/Profile/Product/Header.vue' /* webpackChunkName: "components/base-profile-product-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductLayout = () => import('../../components/Base/Profile/Product/Layout.vue' /* webpackChunkName: "components/base-profile-product-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductUserMenu = () => import('../../components/Base/Profile/Product/UserMenu.vue' /* webpackChunkName: "components/base-profile-product-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamBroadcast = () => import('../../components/Base/Profile/Stream/Broadcast.vue' /* webpackChunkName: "components/base-profile-stream-broadcast" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamHeader = () => import('../../components/Base/Profile/Stream/Header.vue' /* webpackChunkName: "components/base-profile-stream-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamLayout = () => import('../../components/Base/Profile/Stream/Layout.vue' /* webpackChunkName: "components/base-profile-stream-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamUserMenu = () => import('../../components/Base/Profile/Stream/UserMenu.vue' /* webpackChunkName: "components/base-profile-stream-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileTrendCover = () => import('../../components/Base/Profile/Trend/Cover.vue' /* webpackChunkName: "components/base-profile-trend-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileTrendHeader = () => import('../../components/Base/Profile/Trend/Header.vue' /* webpackChunkName: "components/base-profile-trend-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileTrendLayout = () => import('../../components/Base/Profile/Trend/Layout.vue' /* webpackChunkName: "components/base-profile-trend-layout" */).then(c => wrapFunctional(c.default || c))
export const BasePromoWidgetsCurrensy = () => import('../../components/Base/Promo/Widgets/Currensy.vue' /* webpackChunkName: "components/base-promo-widgets-currensy" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsBroadcastsAuthors = () => import('../../components/Base/Recommendations/Broadcasts/Authors.vue' /* webpackChunkName: "components/base-recommendations-broadcasts-authors" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityExperts = () => import('../../components/Base/Recommendations/Community/Experts.vue' /* webpackChunkName: "components/base-recommendations-community-experts" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamExperts = () => import('../../components/Base/Recommendations/Stream/Experts.vue' /* webpackChunkName: "components/base-recommendations-stream-experts" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamOne = () => import('../../components/Base/Recommendations/Stream/One.vue' /* webpackChunkName: "components/base-recommendations-stream-one" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamStreams = () => import('../../components/Base/Recommendations/Stream/Streams.vue' /* webpackChunkName: "components/base-recommendations-stream-streams" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamsExperts = () => import('../../components/Base/Recommendations/Streams/Experts.vue' /* webpackChunkName: "components/base-recommendations-streams-experts" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenuBroadcasts = () => import('../../components/Base/User/Menu/Broadcasts.vue' /* webpackChunkName: "components/base-user-menu-broadcasts" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenuEvents = () => import('../../components/Base/User/Menu/Events.vue' /* webpackChunkName: "components/base-user-menu-events" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenuLoginAs = () => import('../../components/Base/User/Menu/LoginAs.vue' /* webpackChunkName: "components/base-user-menu-login-as" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenuModalLayout = () => import('../../components/Base/User/Menu/ModalLayout.vue' /* webpackChunkName: "components/base-user-menu-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenuOffers = () => import('../../components/Base/User/Menu/Offers.vue' /* webpackChunkName: "components/base-user-menu-offers" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenu = () => import('../../components/Base/User/Menu/index.vue' /* webpackChunkName: "components/base-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileAvatar = () => import('../../components/Base/User/Profile/Avatar.vue' /* webpackChunkName: "components/base-user-profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileEmail = () => import('../../components/Base/User/Profile/Email.vue' /* webpackChunkName: "components/base-user-profile-email" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileFirstName = () => import('../../components/Base/User/Profile/FirstName.vue' /* webpackChunkName: "components/base-user-profile-first-name" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileLastName = () => import('../../components/Base/User/Profile/LastName.vue' /* webpackChunkName: "components/base-user-profile-last-name" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileLogin = () => import('../../components/Base/User/Profile/Login.vue' /* webpackChunkName: "components/base-user-profile-login" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileMiddleName = () => import('../../components/Base/User/Profile/MiddleName.vue' /* webpackChunkName: "components/base-user-profile-middle-name" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfilePhone = () => import('../../components/Base/User/Profile/Phone.vue' /* webpackChunkName: "components/base-user-profile-phone" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsByAuthors = () => import('../../components/Pages/About/Extentions/ByAuthors.vue' /* webpackChunkName: "components/pages-about-extentions-by-authors" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsCalculator = () => import('../../components/Pages/About/Extentions/Calculator.vue' /* webpackChunkName: "components/pages-about-extentions-calculator" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsCompanys = () => import('../../components/Pages/About/Extentions/Companys.vue' /* webpackChunkName: "components/pages-about-extentions-companys" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsForm = () => import('../../components/Pages/About/Extentions/Form.vue' /* webpackChunkName: "components/pages-about-extentions-form" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsIntro = () => import('../../components/Pages/About/Extentions/Intro.vue' /* webpackChunkName: "components/pages-about-extentions-intro" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsRegister = () => import('../../components/Pages/About/Extentions/Register.vue' /* webpackChunkName: "components/pages-about-extentions-register" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsStreams = () => import('../../components/Pages/About/Extentions/Streams.vue' /* webpackChunkName: "components/pages-about-extentions-streams" */).then(c => wrapFunctional(c.default || c))
export const PagesCrmTableAdmin = () => import('../../components/Pages/Crm/Table/Admin.vue' /* webpackChunkName: "components/pages-crm-table-admin" */).then(c => wrapFunctional(c.default || c))
export const PagesCrmTableUser = () => import('../../components/Pages/Crm/Table/User.vue' /* webpackChunkName: "components/pages-crm-table-user" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexTrendsItem = () => import('../../components/Pages/Index/Trends/Item.vue' /* webpackChunkName: "components/pages-index-trends-item" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexTrends = () => import('../../components/Pages/Index/Trends/index.vue' /* webpackChunkName: "components/pages-index-trends" */).then(c => wrapFunctional(c.default || c))
export const PagesErrors404 = () => import('../../components/Pages/Errors/404/index.vue' /* webpackChunkName: "components/pages-errors404" */).then(c => wrapFunctional(c.default || c))
export const CoreInputImgDragDrop = () => import('../../components/Core/Input/Img/DragDrop/DragDrop.vue' /* webpackChunkName: "components/core-input-img-drag-drop" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextMixinsMixin = () => import('../../components/Core/Input/Text/mixins/mixin.js' /* webpackChunkName: "components/core-input-text-mixins-mixin" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsLoginValidator = () => import('../../components/Core/Input/Text/validators/loginValidator.js' /* webpackChunkName: "components/core-input-text-validators-login-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsMaxValidator = () => import('../../components/Core/Input/Text/validators/maxValidator.js' /* webpackChunkName: "components/core-input-text-validators-max-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsMinValidator = () => import('../../components/Core/Input/Text/validators/minValidator.js' /* webpackChunkName: "components/core-input-text-validators-min-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsNumValidator = () => import('../../components/Core/Input/Text/validators/numValidator.js' /* webpackChunkName: "components/core-input-text-validators-num-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsRequiredValidator = () => import('../../components/Core/Input/Text/validators/requiredValidator.js' /* webpackChunkName: "components/core-input-text-validators-required-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextValidatorsRussianValidator = () => import('../../components/Core/Input/Text/validators/russianValidator.js' /* webpackChunkName: "components/core-input-text-validators-russian-validator" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAuthorWidgetsArticleAuthor = () => import('../../components/Megasreda/Author/Widgets/ArticleAuthor/ArticleAuthor.vue' /* webpackChunkName: "components/megasreda-author-widgets-article-author" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAuthorWidgetsRecomendedAuthors = () => import('../../components/Megasreda/Author/Widgets/RecomendedAuthors/RecomendedAuthors.vue' /* webpackChunkName: "components/megasreda-author-widgets-recomended-authors" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAuthorWidgetsRecomendedExperts = () => import('../../components/Megasreda/Author/Widgets/RecomendedExperts/RecomendedExperts.vue' /* webpackChunkName: "components/megasreda-author-widgets-recomended-experts" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsFeedCard = () => import('../../components/Megasreda/Content/Events/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-events-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsFeedCardDraft = () => import('../../components/Megasreda/Content/Events/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-events-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsFeedCardLight = () => import('../../components/Megasreda/Content/Events/FeedCard/FeedCardLight.vue' /* webpackChunkName: "components/megasreda-content-events-feed-card-light" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsGridCard = () => import('../../components/Megasreda/Content/Events/GridCard/GridCard.vue' /* webpackChunkName: "components/megasreda-content-events-grid-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsHeaderInfo = () => import('../../components/Megasreda/Content/Events/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-events-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsHeaderUserMenu = () => import('../../components/Megasreda/Content/Events/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-events-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentEventsUploadImg = () => import('../../components/Megasreda/Content/Events/UploadImg/index.vue' /* webpackChunkName: "components/megasreda-content-events-upload-img" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentGoodsFeedCard = () => import('../../components/Megasreda/Content/Goods/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-goods-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentGoodsFeedCardDraft = () => import('../../components/Megasreda/Content/Goods/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-goods-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentGoodsGridCard = () => import('../../components/Megasreda/Content/Goods/GridCard/GridCard.vue' /* webpackChunkName: "components/megasreda-content-goods-grid-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentGoodsHeaderInfo = () => import('../../components/Megasreda/Content/Goods/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-goods-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentGoodsHeaderUserMenu = () => import('../../components/Megasreda/Content/Goods/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-goods-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentOffersHeaderUserMenu = () => import('../../components/Megasreda/Content/Offers/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-offers-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostFeedCard = () => import('../../components/Megasreda/Content/Post/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-post-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostFeedCardDraft = () => import('../../components/Megasreda/Content/Post/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-post-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostFeedCardTelegram = () => import('../../components/Megasreda/Content/Post/FeedCard/FeedCardTelegram.vue' /* webpackChunkName: "components/megasreda-content-post-feed-card-telegram" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostFeedCardTelegramDraft = () => import('../../components/Megasreda/Content/Post/FeedCard/FeedCardTelegramDraft.vue' /* webpackChunkName: "components/megasreda-content-post-feed-card-telegram-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostGridCard = () => import('../../components/Megasreda/Content/Post/GridCard/GridCard.vue' /* webpackChunkName: "components/megasreda-content-post-grid-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostHeaderInfo = () => import('../../components/Megasreda/Content/Post/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-post-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentPostHeaderUserMenu = () => import('../../components/Megasreda/Content/Post/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-post-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductFeedCard = () => import('../../components/Megasreda/Content/Product/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-product-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductFeedCardDraft = () => import('../../components/Megasreda/Content/Product/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-product-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductFeedCardLight = () => import('../../components/Megasreda/Content/Product/FeedCard/FeedCardLight.vue' /* webpackChunkName: "components/megasreda-content-product-feed-card-light" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductGridCard = () => import('../../components/Megasreda/Content/Product/GridCard/GridCard.vue' /* webpackChunkName: "components/megasreda-content-product-grid-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductHeaderInfo = () => import('../../components/Megasreda/Content/Product/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-product-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentProductHeaderUserMenu = () => import('../../components/Megasreda/Content/Product/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-product-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsHeaderInfo = () => import('../../components/Megasreda/Content/Recommendations/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-recommendations-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentRecommendationsHeaderUserMenu = () => import('../../components/Megasreda/Content/Recommendations/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-recommendations-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVacancysFeedCard = () => import('../../components/Megasreda/Content/Vacancys/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-vacancys-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVacancysFeedCardDraft = () => import('../../components/Megasreda/Content/Vacancys/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-vacancys-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVacancysHeaderInfo = () => import('../../components/Megasreda/Content/Vacancys/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-vacancys-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVacancysHeaderUserMenu = () => import('../../components/Megasreda/Content/Vacancys/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-vacancys-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVideoFeedCard = () => import('../../components/Megasreda/Content/Video/FeedCard/FeedCard.vue' /* webpackChunkName: "components/megasreda-content-video-feed-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVideoFeedCardDraft = () => import('../../components/Megasreda/Content/Video/FeedCard/FeedCardDraft.vue' /* webpackChunkName: "components/megasreda-content-video-feed-card-draft" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVideoGridCard = () => import('../../components/Megasreda/Content/Video/GridCard/GridCard.vue' /* webpackChunkName: "components/megasreda-content-video-grid-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVideoHeaderInfo = () => import('../../components/Megasreda/Content/Video/Header/Info.vue' /* webpackChunkName: "components/megasreda-content-video-header-info" */).then(c => wrapFunctional(c.default || c))
export const MegasredaContentVideoHeaderUserMenu = () => import('../../components/Megasreda/Content/Video/Header/UserMenu.vue' /* webpackChunkName: "components/megasreda-content-video-header-user-menu" */).then(c => wrapFunctional(c.default || c))
export const MegasredaPlaceMenuItem = () => import('../../components/Megasreda/Place/Menu/Item/Item.vue' /* webpackChunkName: "components/megasreda-place-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksBase = () => import('../../components/Megasreda/Recomended/Author/Blocks/Base.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-base" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedSocialItem = () => import('../../components/Megasreda/Recomended/Social/Item/Item.vue' /* webpackChunkName: "components/megasreda-recomended-social-item" */).then(c => wrapFunctional(c.default || c))
export const MegasredaStreamsWidgetsArticleStream = () => import('../../components/Megasreda/Streams/Widgets/ArticleStream/ArticleStream.vue' /* webpackChunkName: "components/megasreda-streams-widgets-article-stream" */).then(c => wrapFunctional(c.default || c))
export const MegasredaStreamsWidgetsRecomendedStreams = () => import('../../components/Megasreda/Streams/Widgets/RecomendedStreams/RecomendedStreams.vue' /* webpackChunkName: "components/megasreda-streams-widgets-recomended-streams" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsGiftsForm = () => import('../../components/Megastudio/Settings/Extensions/Gifts/Form.vue' /* webpackChunkName: "components/megastudio-settings-extensions-gifts-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsGifts = () => import('../../components/Megastudio/Settings/Extensions/Gifts/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-gifts" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsModulesForm = () => import('../../components/Megastudio/Settings/Extensions/Modules/Form.vue' /* webpackChunkName: "components/megastudio-settings-extensions-modules-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsModules = () => import('../../components/Megastudio/Settings/Extensions/Modules/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-modules" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsPeriodsForm = () => import('../../components/Megastudio/Settings/Extensions/Periods/Form.vue' /* webpackChunkName: "components/megastudio-settings-extensions-periods-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsPeriods = () => import('../../components/Megastudio/Settings/Extensions/Periods/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-periods" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsServicesForm = () => import('../../components/Megastudio/Settings/Extensions/Services/Form.vue' /* webpackChunkName: "components/megastudio-settings-extensions-services-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsServices = () => import('../../components/Megastudio/Settings/Extensions/Services/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-services" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsSocialChannelsForm = () => import('../../components/Megastudio/Settings/Social/Channels/Form.vue' /* webpackChunkName: "components/megastudio-settings-social-channels-form" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsTariffsListItem = () => import('../../components/Megastudio/Settings/Tariffs/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-tariffs-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsTariffsList = () => import('../../components/Megastudio/Settings/Tariffs/List/index.vue' /* webpackChunkName: "components/megastudio-settings-tariffs-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioStreamImagesAdd = () => import('../../components/Megastudio/Stream/Images/Add/Add.vue' /* webpackChunkName: "components/megastudio-stream-images-add" */).then(c => wrapFunctional(c.default || c))
export const MegastudioTariffsComparisonTableOneTimeTable = () => import('../../components/Megastudio/Tariffs/Comparison/Table/OneTimeTable.vue' /* webpackChunkName: "components/megastudio-tariffs-comparison-table-one-time-table" */).then(c => wrapFunctional(c.default || c))
export const BaseContentBlockEmbed = () => import('../../components/Base/Content/Block/Embed/Embed.vue' /* webpackChunkName: "components/base-content-block-embed" */).then(c => wrapFunctional(c.default || c))
export const BaseContentBlockListItem = () => import('../../components/Base/Content/Block/ListItem/ListItem.vue' /* webpackChunkName: "components/base-content-block-list-item" */).then(c => wrapFunctional(c.default || c))
export const BaseContentBlockOrderListItem = () => import('../../components/Base/Content/Block/OrderListItem/OrderListItem.vue' /* webpackChunkName: "components/base-content-block-order-list-item" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorCompanyCard = () => import('../../components/Base/Navigator/Company/Card/Card.vue' /* webpackChunkName: "components/base-navigator-company-card" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorProductCard = () => import('../../components/Base/Navigator/Product/Card/Card.vue' /* webpackChunkName: "components/base-navigator-product-card" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorProductCardUserMenu = () => import('../../components/Base/Navigator/Product/Card/UserMenu.vue' /* webpackChunkName: "components/base-navigator-product-card-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorStreamCard = () => import('../../components/Base/Navigator/Stream/Card/Card.vue' /* webpackChunkName: "components/base-navigator-stream-card" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorStreamCardUserMenu = () => import('../../components/Base/Navigator/Stream/Card/UserMenu.vue' /* webpackChunkName: "components/base-navigator-stream-card-user-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorSubscriptionsAuthorBlock = () => import('../../components/Base/Navigator/Subscriptions/Author/Block.vue' /* webpackChunkName: "components/base-navigator-subscriptions-author-block" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorSubscriptionsCompanysBlock = () => import('../../components/Base/Navigator/Subscriptions/Companys/Block.vue' /* webpackChunkName: "components/base-navigator-subscriptions-companys-block" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorSubscriptionsStreamsBlock = () => import('../../components/Base/Navigator/Subscriptions/Streams/Block.vue' /* webpackChunkName: "components/base-navigator-subscriptions-streams-block" */).then(c => wrapFunctional(c.default || c))
export const BaseNavigatorUserCard = () => import('../../components/Base/Navigator/User/Card/Card.vue' /* webpackChunkName: "components/base-navigator-user-card" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCertificates = () => import('../../components/Base/Profile/Author/Certificates/Certificates.vue' /* webpackChunkName: "components/base-profile-author-certificates" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCertificatesItem = () => import('../../components/Base/Profile/Author/Certificates/Item.vue' /* webpackChunkName: "components/base-profile-author-certificates-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCompanyHeader = () => import('../../components/Base/Profile/Author/Company/Header.vue' /* webpackChunkName: "components/base-profile-author-company-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCompanyLogo = () => import('../../components/Base/Profile/Author/Company/Logo.vue' /* webpackChunkName: "components/base-profile-author-company-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCover = () => import('../../components/Base/Profile/Author/Cover/Cover.vue' /* webpackChunkName: "components/base-profile-author-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCoverSelf = () => import('../../components/Base/Profile/Author/Cover/Self.vue' /* webpackChunkName: "components/base-profile-author-cover-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCustomers = () => import('../../components/Base/Profile/Author/Customers/Customers.vue' /* webpackChunkName: "components/base-profile-author-customers" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCustomersItem = () => import('../../components/Base/Profile/Author/Customers/Item.vue' /* webpackChunkName: "components/base-profile-author-customers-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorDiagnostics = () => import('../../components/Base/Profile/Author/Diagnostics/Diagnostics.vue' /* webpackChunkName: "components/base-profile-author-diagnostics" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorDiagnosticsStatistics = () => import('../../components/Base/Profile/Author/Diagnostics/Statistics.vue' /* webpackChunkName: "components/base-profile-author-diagnostics-statistics" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorDiagnosticsTasks = () => import('../../components/Base/Profile/Author/Diagnostics/Tasks.vue' /* webpackChunkName: "components/base-profile-author-diagnostics-tasks" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLogo = () => import('../../components/Base/Profile/Author/Logo/Logo.vue' /* webpackChunkName: "components/base-profile-author-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLogoSelf = () => import('../../components/Base/Profile/Author/Logo/Self.vue' /* webpackChunkName: "components/base-profile-author-logo-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorRecommendationsItem = () => import('../../components/Base/Profile/Author/Recommendations/Item.vue' /* webpackChunkName: "components/base-profile-author-recommendations-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorRecommendations = () => import('../../components/Base/Profile/Author/Recommendations/index.vue' /* webpackChunkName: "components/base-profile-author-recommendations" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorRecommendationsLight = () => import('../../components/Base/Profile/Author/Recommendations/light.vue' /* webpackChunkName: "components/base-profile-author-recommendations-light" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorUserHeader = () => import('../../components/Base/Profile/Author/User/Header.vue' /* webpackChunkName: "components/base-profile-author-user-header" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileBroadcastsCover = () => import('../../components/Base/Profile/Broadcasts/Cover/Cover.vue' /* webpackChunkName: "components/base-profile-broadcasts-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileBroadcastsLogo = () => import('../../components/Base/Profile/Broadcasts/Logo/Logo.vue' /* webpackChunkName: "components/base-profile-broadcasts-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityCover = () => import('../../components/Base/Profile/Community/Cover/Cover.vue' /* webpackChunkName: "components/base-profile-community-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityCoverSelf = () => import('../../components/Base/Profile/Community/Cover/Self.vue' /* webpackChunkName: "components/base-profile-community-cover-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityLogo = () => import('../../components/Base/Profile/Community/Logo/Logo.vue' /* webpackChunkName: "components/base-profile-community-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityLogoSelf = () => import('../../components/Base/Profile/Community/Logo/Self.vue' /* webpackChunkName: "components/base-profile-community-logo-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductCover = () => import('../../components/Base/Profile/Product/Cover/Cover.vue' /* webpackChunkName: "components/base-profile-product-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductCoverSelf = () => import('../../components/Base/Profile/Product/Cover/Self.vue' /* webpackChunkName: "components/base-profile-product-cover-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductImages = () => import('../../components/Base/Profile/Product/Images/Images.vue' /* webpackChunkName: "components/base-profile-product-images" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductImagesItem = () => import('../../components/Base/Profile/Product/Images/Item.vue' /* webpackChunkName: "components/base-profile-product-images-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductLogo = () => import('../../components/Base/Profile/Product/Logo/Logo.vue' /* webpackChunkName: "components/base-profile-product-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductLogoSelf = () => import('../../components/Base/Profile/Product/Logo/Self.vue' /* webpackChunkName: "components/base-profile-product-logo-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamCover = () => import('../../components/Base/Profile/Stream/Cover/Cover.vue' /* webpackChunkName: "components/base-profile-stream-cover" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamCoverSelf = () => import('../../components/Base/Profile/Stream/Cover/Self.vue' /* webpackChunkName: "components/base-profile-stream-cover-self" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamImages = () => import('../../components/Base/Profile/Stream/Images/Images.vue' /* webpackChunkName: "components/base-profile-stream-images" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamImagesItem = () => import('../../components/Base/Profile/Stream/Images/Item.vue' /* webpackChunkName: "components/base-profile-stream-images-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamLogo = () => import('../../components/Base/Profile/Stream/Logo/Logo.vue' /* webpackChunkName: "components/base-profile-stream-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamLogoSelf = () => import('../../components/Base/Profile/Stream/Logo/Self.vue' /* webpackChunkName: "components/base-profile-stream-logo-self" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityEventsBlock = () => import('../../components/Base/Recommendations/Community/Events/Block.vue' /* webpackChunkName: "components/base-recommendations-community-events-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityEventsCard = () => import('../../components/Base/Recommendations/Community/Events/Card.vue' /* webpackChunkName: "components/base-recommendations-community-events-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityMaterialsBlock = () => import('../../components/Base/Recommendations/Community/Materials/Block.vue' /* webpackChunkName: "components/base-recommendations-community-materials-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityMaterialsCard = () => import('../../components/Base/Recommendations/Community/Materials/Card.vue' /* webpackChunkName: "components/base-recommendations-community-materials-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityOffersBlock = () => import('../../components/Base/Recommendations/Community/Offers/Block.vue' /* webpackChunkName: "components/base-recommendations-community-offers-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityOffersCard = () => import('../../components/Base/Recommendations/Community/Offers/Card.vue' /* webpackChunkName: "components/base-recommendations-community-offers-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityVideoBlock = () => import('../../components/Base/Recommendations/Community/Video/Block.vue' /* webpackChunkName: "components/base-recommendations-community-video-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsCommunityVideoCard = () => import('../../components/Base/Recommendations/Community/Video/Card.vue' /* webpackChunkName: "components/base-recommendations-community-video-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamEventsBlock = () => import('../../components/Base/Recommendations/Stream/Events/Block.vue' /* webpackChunkName: "components/base-recommendations-stream-events-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamEventsCard = () => import('../../components/Base/Recommendations/Stream/Events/Card.vue' /* webpackChunkName: "components/base-recommendations-stream-events-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamMaterialsBlock = () => import('../../components/Base/Recommendations/Stream/Materials/Block.vue' /* webpackChunkName: "components/base-recommendations-stream-materials-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamMaterialsCard = () => import('../../components/Base/Recommendations/Stream/Materials/Card.vue' /* webpackChunkName: "components/base-recommendations-stream-materials-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamOffersBlock = () => import('../../components/Base/Recommendations/Stream/Offers/Block.vue' /* webpackChunkName: "components/base-recommendations-stream-offers-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamOffersCard = () => import('../../components/Base/Recommendations/Stream/Offers/Card.vue' /* webpackChunkName: "components/base-recommendations-stream-offers-card" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamVideoBlock = () => import('../../components/Base/Recommendations/Stream/Video/Block.vue' /* webpackChunkName: "components/base-recommendations-stream-video-block" */).then(c => wrapFunctional(c.default || c))
export const BaseRecommendationsStreamVideoCard = () => import('../../components/Base/Recommendations/Stream/Video/Card.vue' /* webpackChunkName: "components/base-recommendations-stream-video-card" */).then(c => wrapFunctional(c.default || c))
export const BaseSkillWidgetProduct = () => import('../../components/Base/Skill/Widget/Product/Product.vue' /* webpackChunkName: "components/base-skill-widget-product" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionWidgetAuthor = () => import('../../components/Base/Subscription/Widget/Author/Author.vue' /* webpackChunkName: "components/base-subscription-widget-author" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionWidgetCollection = () => import('../../components/Base/Subscription/Widget/Collection.vue/Collection.vue' /* webpackChunkName: "components/base-subscription-widget-collection" */).then(c => wrapFunctional(c.default || c))
export const BaseSubscriptionWidgetStream = () => import('../../components/Base/Subscription/Widget/Stream/Stream.vue' /* webpackChunkName: "components/base-subscription-widget-stream" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessContacts = () => import('../../components/Base/User/Cabinet/Business/Contacts.vue' /* webpackChunkName: "components/base-user-cabinet-business-contacts" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessDescription = () => import('../../components/Base/User/Cabinet/Business/Description.vue' /* webpackChunkName: "components/base-user-cabinet-business-description" */).then(c => wrapFunctional(c.default || c))
export const BaseUserConfirmEmailSendButton = () => import('../../components/Base/User/Confirm/Email/SendButton.vue' /* webpackChunkName: "components/base-user-confirm-email-send-button" */).then(c => wrapFunctional(c.default || c))
export const BaseUserConfirmEmailSendMessage = () => import('../../components/Base/User/Confirm/Email/SendMessage.vue' /* webpackChunkName: "components/base-user-confirm-email-send-message" */).then(c => wrapFunctional(c.default || c))
export const BaseUserProfileMixinsMixin = () => import('../../components/Base/User/Profile/mixins/mixin.vue' /* webpackChunkName: "components/base-user-profile-mixins-mixin" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessArticles = () => import('../../components/Pages/About/Extentions/Business/Articles.vue' /* webpackChunkName: "components/pages-about-extentions-business-articles" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessContent = () => import('../../components/Pages/About/Extentions/Business/Content.vue' /* webpackChunkName: "components/pages-about-extentions-business-content" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessForm = () => import('../../components/Pages/About/Extentions/Business/Form.vue' /* webpackChunkName: "components/pages-about-extentions-business-form" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessFunctions = () => import('../../components/Pages/About/Extentions/Business/Functions.vue' /* webpackChunkName: "components/pages-about-extentions-business-functions" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessIntro = () => import('../../components/Pages/About/Extentions/Business/Intro.vue' /* webpackChunkName: "components/pages-about-extentions-business-intro" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessPurposes = () => import('../../components/Pages/About/Extentions/Business/Purposes.vue' /* webpackChunkName: "components/pages-about-extentions-business-purposes" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessReviews = () => import('../../components/Pages/About/Extentions/Business/Reviews.vue' /* webpackChunkName: "components/pages-about-extentions-business-reviews" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessSteps = () => import('../../components/Pages/About/Extentions/Business/Steps.vue' /* webpackChunkName: "components/pages-about-extentions-business-steps" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsLayout = () => import('../../components/Pages/About/Extentions/Layout/index.vue' /* webpackChunkName: "components/pages-about-extentions-layout" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsMenu = () => import('../../components/Pages/About/Extentions/Menu/Menu.vue' /* webpackChunkName: "components/pages-about-extentions-menu" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesAuthors = () => import('../../components/Pages/About/Extentions/Services/Authors.vue' /* webpackChunkName: "components/pages-about-extentions-services-authors" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesCompanys = () => import('../../components/Pages/About/Extentions/Services/Companys.vue' /* webpackChunkName: "components/pages-about-extentions-services-companys" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesIntro = () => import('../../components/Pages/About/Extentions/Services/Intro.vue' /* webpackChunkName: "components/pages-about-extentions-services-intro" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesManagement = () => import('../../components/Pages/About/Extentions/Services/Management.vue' /* webpackChunkName: "components/pages-about-extentions-services-management" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesStreams = () => import('../../components/Pages/About/Extentions/Services/Streams.vue' /* webpackChunkName: "components/pages-about-extentions-services-streams" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServices = () => import('../../components/Pages/About/Extentions/Services/index.vue' /* webpackChunkName: "components/pages-about-extentions-services" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersArticles = () => import('../../components/Pages/About/Extentions/Users/Articles.vue' /* webpackChunkName: "components/pages-about-extentions-users-articles" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersBecause = () => import('../../components/Pages/About/Extentions/Users/Because.vue' /* webpackChunkName: "components/pages-about-extentions-users-because" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersEvents = () => import('../../components/Pages/About/Extentions/Users/Events.vue' /* webpackChunkName: "components/pages-about-extentions-users-events" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersExperts = () => import('../../components/Pages/About/Extentions/Users/Experts.vue' /* webpackChunkName: "components/pages-about-extentions-users-experts" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersInter = () => import('../../components/Pages/About/Extentions/Users/Inter.vue' /* webpackChunkName: "components/pages-about-extentions-users-inter" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersIntro = () => import('../../components/Pages/About/Extentions/Users/Intro.vue' /* webpackChunkName: "components/pages-about-extentions-users-intro" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersOffers = () => import('../../components/Pages/About/Extentions/Users/Offers.vue' /* webpackChunkName: "components/pages-about-extentions-users-offers" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersScope = () => import('../../components/Pages/About/Extentions/Users/Scope.vue' /* webpackChunkName: "components/pages-about-extentions-users-scope" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersSteps = () => import('../../components/Pages/About/Extentions/Users/Steps.vue' /* webpackChunkName: "components/pages-about-extentions-users-steps" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastNotificationsForm = () => import('../../components/Pages/Place/Broadcast/Notifications/Form.vue' /* webpackChunkName: "components/pages-place-broadcast-notifications-form" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersForm = () => import('../../components/Pages/Place/Broadcast/Speakers/Form.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-form" */).then(c => wrapFunctional(c.default || c))
export const PagesPlacePostSocialTariffLine = () => import('../../components/Pages/Place/Post/Social/TariffLine.vue' /* webpackChunkName: "components/pages-place-post-social-tariff-line" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesAccount = () => import('../../components/Core/Input/Select/Types/Account/Account.vue' /* webpackChunkName: "components/core-input-select-types-account" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesAccountType = () => import('../../components/Core/Input/Select/Types/AccountType/AccountType.vue' /* webpackChunkName: "components/core-input-select-types-account-type" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesAdvertisementPlaceOfWork = () => import('../../components/Core/Input/Select/Types/AdvertisementPlaceOfWork/AdvertisementPlaceOfWork.vue' /* webpackChunkName: "components/core-input-select-types-advertisement-place-of-work" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesAdvertisementProvisionType = () => import('../../components/Core/Input/Select/Types/AdvertisementProvisionType/AdvertisementProvisionType.vue' /* webpackChunkName: "components/core-input-select-types-advertisement-provision-type" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesCity = () => import('../../components/Core/Input/Select/Types/City/City.vue' /* webpackChunkName: "components/core-input-select-types-city" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesCostForType = () => import('../../components/Core/Input/Select/Types/CostForType/CostForType.vue' /* webpackChunkName: "components/core-input-select-types-cost-for-type" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesCostType = () => import('../../components/Core/Input/Select/Types/CostType/CostType.vue' /* webpackChunkName: "components/core-input-select-types-cost-type" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesDateRange = () => import('../../components/Core/Input/Select/Types/DateRange/DateRange.vue' /* webpackChunkName: "components/core-input-select-types-date-range" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesDefault = () => import('../../components/Core/Input/Select/Types/Default/Default.vue' /* webpackChunkName: "components/core-input-select-types-default" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesFormat = () => import('../../components/Core/Input/Select/Types/Format/Format.vue' /* webpackChunkName: "components/core-input-select-types-format" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesIcons = () => import('../../components/Core/Input/Select/Types/Icons/Icons.vue' /* webpackChunkName: "components/core-input-select-types-icons" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesProduct = () => import('../../components/Core/Input/Select/Types/Product/Product.vue' /* webpackChunkName: "components/core-input-select-types-product" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesStream = () => import('../../components/Core/Input/Select/Types/Stream/Stream.vue' /* webpackChunkName: "components/core-input-select-types-stream" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesStreamUrl = () => import('../../components/Core/Input/Select/Types/StreamUrl/StreamUrl.vue' /* webpackChunkName: "components/core-input-select-types-stream-url" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesBase = () => import('../../components/Core/Input/Text/Types/Base/Base.vue' /* webpackChunkName: "components/core-input-text-types-base" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesDate = () => import('../../components/Core/Input/Text/Types/Date/Date.vue' /* webpackChunkName: "components/core-input-text-types-date" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesDateTime = () => import('../../components/Core/Input/Text/Types/Date/DateTime.vue' /* webpackChunkName: "components/core-input-text-types-date-time" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesDefault = () => import('../../components/Core/Input/Text/Types/Default/Default.vue' /* webpackChunkName: "components/core-input-text-types-default" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesEmail = () => import('../../components/Core/Input/Text/Types/Email/Email.vue' /* webpackChunkName: "components/core-input-text-types-email" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesInn = () => import('../../components/Core/Input/Text/Types/Inn/Inn.vue' /* webpackChunkName: "components/core-input-text-types-inn" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesNumber = () => import('../../components/Core/Input/Text/Types/Number/Number.vue' /* webpackChunkName: "components/core-input-text-types-number" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesPassword = () => import('../../components/Core/Input/Text/Types/Password/Password.vue' /* webpackChunkName: "components/core-input-text-types-password" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesPhone = () => import('../../components/Core/Input/Text/Types/Phone/Phone.vue' /* webpackChunkName: "components/core-input-text-types-phone" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesUrl = () => import('../../components/Core/Input/Text/Types/Url/Url.vue' /* webpackChunkName: "components/core-input-text-types-url" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesUsername = () => import('../../components/Core/Input/Text/Types/Username/Username.vue' /* webpackChunkName: "components/core-input-text-types-username" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAuthorWidgetsRecomendedAuthorsItem = () => import('../../components/Megasreda/Author/Widgets/RecomendedAuthors/Item/Item.vue' /* webpackChunkName: "components/megasreda-author-widgets-recomended-authors-item" */).then(c => wrapFunctional(c.default || c))
export const MegasredaAuthorWidgetsRecomendedExpertsItem = () => import('../../components/Megasreda/Author/Widgets/RecomendedExperts/Item/Item.vue' /* webpackChunkName: "components/megasreda-author-widgets-recomended-experts-item" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksEventsBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Events/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-events-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksEventsCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Events/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-events-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksGoodsBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Goods/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-goods-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksGoodsCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Goods/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-goods-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksMaterialsBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Materials/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-materials-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksMaterialsCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Materials/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-materials-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksOffersBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Offers/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-offers-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksOffersCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Offers/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-offers-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksVacancysBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Vacancys/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-vacancys-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksVacancysCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Vacancys/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-vacancys-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksVideoBlock = () => import('../../components/Megasreda/Recomended/Author/Blocks/Video/Block.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-video-block" */).then(c => wrapFunctional(c.default || c))
export const MegasredaRecomendedAuthorBlocksVideoCard = () => import('../../components/Megasreda/Recomended/Author/Blocks/Video/Card.vue' /* webpackChunkName: "components/megasreda-recomended-author-blocks-video-card" */).then(c => wrapFunctional(c.default || c))
export const MegasredaStreamsWidgetsRecomendedStreamsItem = () => import('../../components/Megasreda/Streams/Widgets/RecomendedStreams/Item/Item.vue' /* webpackChunkName: "components/megasreda-streams-widgets-recomended-streams-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsGiftsListItem = () => import('../../components/Megastudio/Settings/Extensions/Gifts/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-extensions-gifts-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsGiftsList = () => import('../../components/Megastudio/Settings/Extensions/Gifts/List/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-gifts-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsModulesListItem = () => import('../../components/Megastudio/Settings/Extensions/Modules/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-extensions-modules-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsModulesList = () => import('../../components/Megastudio/Settings/Extensions/Modules/List/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-modules-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsPeriodsListItem = () => import('../../components/Megastudio/Settings/Extensions/Periods/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-extensions-periods-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsPeriodsList = () => import('../../components/Megastudio/Settings/Extensions/Periods/List/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-periods-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsServicesListItem = () => import('../../components/Megastudio/Settings/Extensions/Services/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-extensions-services-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsExtensionsServicesList = () => import('../../components/Megastudio/Settings/Extensions/Services/List/index.vue' /* webpackChunkName: "components/megastudio-settings-extensions-services-list" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsSocialChannelsListAvatar = () => import('../../components/Megastudio/Settings/Social/Channels/List/Avatar.vue' /* webpackChunkName: "components/megastudio-settings-social-channels-list-avatar" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsSocialChannelsListItem = () => import('../../components/Megastudio/Settings/Social/Channels/List/Item.vue' /* webpackChunkName: "components/megastudio-settings-social-channels-list-item" */).then(c => wrapFunctional(c.default || c))
export const MegastudioSettingsSocialChannelsList = () => import('../../components/Megastudio/Settings/Social/Channels/List/index.vue' /* webpackChunkName: "components/megastudio-settings-social-channels-list" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCoverChange = () => import('../../components/Base/Profile/Author/Cover/Change/Change.vue' /* webpackChunkName: "components/base-profile-author-cover-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorCoverChangeSlider = () => import('../../components/Base/Profile/Author/Cover/Change/Slider.vue' /* webpackChunkName: "components/base-profile-author-cover-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLogoChange = () => import('../../components/Base/Profile/Author/Logo/Change/Change.vue' /* webpackChunkName: "components/base-profile-author-logo-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLogoChangeLink = () => import('../../components/Base/Profile/Author/Logo/Change/Link.vue' /* webpackChunkName: "components/base-profile-author-logo-change-link" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileAuthorLogoChangeSlider = () => import('../../components/Base/Profile/Author/Logo/Change/Slider.vue' /* webpackChunkName: "components/base-profile-author-logo-change-slider" */).then(c => wrapFunctional(c.default || c))
export const MegastudioTariffsComparisonTableOptionLineBase = () => import('../../components/Megastudio/Tariffs/Comparison/Table/OptionLine/Base.vue' /* webpackChunkName: "components/megastudio-tariffs-comparison-table-option-line-base" */).then(c => wrapFunctional(c.default || c))
export const MegastudioTariffsComparisonTableOptionLineInfo = () => import('../../components/Megastudio/Tariffs/Comparison/Table/OptionLine/Info.vue' /* webpackChunkName: "components/megastudio-tariffs-comparison-table-option-line-info" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityCoverChange = () => import('../../components/Base/Profile/Community/Cover/Change/Change.vue' /* webpackChunkName: "components/base-profile-community-cover-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityCoverChangeSlider = () => import('../../components/Base/Profile/Community/Cover/Change/Slider.vue' /* webpackChunkName: "components/base-profile-community-cover-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityLogoChange = () => import('../../components/Base/Profile/Community/Logo/Change/Change.vue' /* webpackChunkName: "components/base-profile-community-logo-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileCommunityLogoChangeSlider = () => import('../../components/Base/Profile/Community/Logo/Change/Slider.vue' /* webpackChunkName: "components/base-profile-community-logo-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductCoverChange = () => import('../../components/Base/Profile/Product/Cover/Change/Change.vue' /* webpackChunkName: "components/base-profile-product-cover-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductCoverChangeSlider = () => import('../../components/Base/Profile/Product/Cover/Change/Slider.vue' /* webpackChunkName: "components/base-profile-product-cover-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductLogoChange = () => import('../../components/Base/Profile/Product/Logo/Change/Change.vue' /* webpackChunkName: "components/base-profile-product-logo-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileProductLogoChangeSlider = () => import('../../components/Base/Profile/Product/Logo/Change/Slider.vue' /* webpackChunkName: "components/base-profile-product-logo-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamCoverChange = () => import('../../components/Base/Profile/Stream/Cover/Change/Change.vue' /* webpackChunkName: "components/base-profile-stream-cover-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamCoverChangeSlider = () => import('../../components/Base/Profile/Stream/Cover/Change/Slider.vue' /* webpackChunkName: "components/base-profile-stream-cover-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamLogoChange = () => import('../../components/Base/Profile/Stream/Logo/Change/Change.vue' /* webpackChunkName: "components/base-profile-stream-logo-change" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileStreamLogoChangeSlider = () => import('../../components/Base/Profile/Stream/Logo/Change/Slider.vue' /* webpackChunkName: "components/base-profile-stream-logo-change-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessActionButton = () => import('../../components/Base/User/Cabinet/Business/ActionButton/ActionButton.vue' /* webpackChunkName: "components/base-user-cabinet-business-action-button" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessCertificates = () => import('../../components/Base/User/Cabinet/Business/Certificates/Certificates.vue' /* webpackChunkName: "components/base-user-cabinet-business-certificates" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessClients = () => import('../../components/Base/User/Cabinet/Business/Clients/Clients.vue' /* webpackChunkName: "components/base-user-cabinet-business-clients" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessRatings = () => import('../../components/Base/User/Cabinet/Business/Ratings/Ratings.vue' /* webpackChunkName: "components/base-user-cabinet-business-ratings" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsBusinessCreateProfile = () => import('../../components/Pages/About/Extentions/Business/Create/Profile.vue' /* webpackChunkName: "components/pages-about-extentions-business-create-profile" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesOffersCard = () => import('../../components/Pages/About/Extentions/Services/Offers/Card.vue' /* webpackChunkName: "components/pages-about-extentions-services-offers-card" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesOffers = () => import('../../components/Pages/About/Extentions/Services/Offers/index.vue' /* webpackChunkName: "components/pages-about-extentions-services-offers" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesProductsCard = () => import('../../components/Pages/About/Extentions/Services/Products/Card.vue' /* webpackChunkName: "components/pages-about-extentions-services-products-card" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsServicesProducts = () => import('../../components/Pages/About/Extentions/Services/Products/index.vue' /* webpackChunkName: "components/pages-about-extentions-services-products" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersReviewsCard = () => import('../../components/Pages/About/Extentions/Users/Reviews/Card.vue' /* webpackChunkName: "components/pages-about-extentions-users-reviews-card" */).then(c => wrapFunctional(c.default || c))
export const PagesAboutExtentionsUsersReviews = () => import('../../components/Pages/About/Extentions/Users/Reviews/index.vue' /* webpackChunkName: "components/pages-about-extentions-users-reviews" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastNotificationsList = () => import('../../components/Pages/Place/Broadcast/Notifications/List/Index.vue' /* webpackChunkName: "components/pages-place-broadcast-notifications-list" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastNotificationsListItem = () => import('../../components/Pages/Place/Broadcast/Notifications/List/item.vue' /* webpackChunkName: "components/pages-place-broadcast-notifications-list-item" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersAvatarChange = () => import('../../components/Pages/Place/Broadcast/Speakers/Avatar/Change.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-avatar-change" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersAvatar = () => import('../../components/Pages/Place/Broadcast/Speakers/Avatar/Index.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-avatar" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersAvatarSlider = () => import('../../components/Pages/Place/Broadcast/Speakers/Avatar/Slider.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-avatar-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersListItem = () => import('../../components/Pages/Place/Broadcast/Speakers/List/Item.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-list-item" */).then(c => wrapFunctional(c.default || c))
export const PagesPlaceBroadcastSpeakersList = () => import('../../components/Pages/Place/Broadcast/Speakers/List/index.vue' /* webpackChunkName: "components/pages-place-broadcast-speakers-list" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesVacancyCostType = () => import('../../components/Core/Input/Select/Types/Vacancy/CostType/index.vue' /* webpackChunkName: "components/core-input-select-types-vacancy-cost-type" */).then(c => wrapFunctional(c.default || c))
export const CoreInputSelectTypesVacancyEmployment = () => import('../../components/Core/Input/Select/Types/Vacancy/Employment/index.vue' /* webpackChunkName: "components/core-input-select-types-vacancy-employment" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesEmailValidator = () => import('../../components/Core/Input/Text/Types/Email/validators/emailValidator.js' /* webpackChunkName: "components/core-input-text-types-email-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesInnMask = () => import('../../components/Core/Input/Text/Types/Inn/masks/innMask.js' /* webpackChunkName: "components/core-input-text-types-inn-mask" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesInnValidator = () => import('../../components/Core/Input/Text/Types/Inn/validators/innValidator.js' /* webpackChunkName: "components/core-input-text-types-inn-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesPasswordValidator = () => import('../../components/Core/Input/Text/Types/Password/validators/passwordValidator.js' /* webpackChunkName: "components/core-input-text-types-password-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesPhoneMask = () => import('../../components/Core/Input/Text/Types/Phone/masks/phoneMask.js' /* webpackChunkName: "components/core-input-text-types-phone-mask" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesPhoneValidator = () => import('../../components/Core/Input/Text/Types/Phone/validators/phoneValidator.js' /* webpackChunkName: "components/core-input-text-types-phone-validator" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextTypesUrlValidator = () => import('../../components/Core/Input/Text/Types/Url/validators/urlValidator.js' /* webpackChunkName: "components/core-input-text-types-url-validator" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessCertificatesAdd = () => import('../../components/Base/User/Cabinet/Business/Certificates/Add/Add.vue' /* webpackChunkName: "components/base-user-cabinet-business-certificates-add" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessClientsAdd = () => import('../../components/Base/User/Cabinet/Business/Clients/Add/Add.vue' /* webpackChunkName: "components/base-user-cabinet-business-clients-add" */).then(c => wrapFunctional(c.default || c))
export const BaseUserCabinetBusinessRatingsRating = () => import('../../components/Base/User/Cabinet/Business/Ratings/Rating/Rating.vue' /* webpackChunkName: "components/base-user-cabinet-business-ratings-rating" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
