import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dad989b0 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _bf3d18a6 = () => interopDefault(import('../pages/broadcasts/index.vue' /* webpackChunkName: "pages/broadcasts/index" */))
const _53a1c470 = () => interopDefault(import('../pages/broadcasts/index/index.vue' /* webpackChunkName: "pages/broadcasts/index/index" */))
const _061b12be = () => interopDefault(import('../pages/broadcasts/index/live.vue' /* webpackChunkName: "pages/broadcasts/index/live" */))
const _9542306a = () => interopDefault(import('../pages/broadcasts/index/premiere.vue' /* webpackChunkName: "pages/broadcasts/index/premiere" */))
const _2b2d774e = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _7e582484 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _0306d839 = () => interopDefault(import('../pages/crm/index.vue' /* webpackChunkName: "pages/crm/index" */))
const _049cea71 = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _019bcf98 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _a7e3edb8 = () => interopDefault(import('../pages/events/companys.vue' /* webpackChunkName: "pages/events/companys" */))
const _76875d4f = () => interopDefault(import('../pages/events/discount.vue' /* webpackChunkName: "pages/events/discount" */))
const _08dddbfa = () => interopDefault(import('../pages/events/free.vue' /* webpackChunkName: "pages/events/free" */))
const _5efd2f40 = () => interopDefault(import('../pages/events/nearest.vue' /* webpackChunkName: "pages/events/nearest" */))
const _4ec33c3b = () => interopDefault(import('../pages/events/popular.vue' /* webpackChunkName: "pages/events/popular" */))
const _1779bbae = () => interopDefault(import('../pages/goods.vue' /* webpackChunkName: "pages/goods" */))
const _0e4a47b1 = () => interopDefault(import('../pages/goods/index.vue' /* webpackChunkName: "pages/goods/index" */))
const _a3a35182 = () => interopDefault(import('../pages/goods/new.vue' /* webpackChunkName: "pages/goods/new" */))
const _0ee4ff10 = () => interopDefault(import('../pages/goods/popular.vue' /* webpackChunkName: "pages/goods/popular" */))
const _cd0837f8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1ef09c23 = () => interopDefault(import('../pages/marketplace.vue' /* webpackChunkName: "pages/marketplace" */))
const _735b8734 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _4993c8ea = () => interopDefault(import('../pages/marketplace/goods.vue' /* webpackChunkName: "pages/marketplace/goods" */))
const _3689339a = () => interopDefault(import('../pages/marketplace/offers.vue' /* webpackChunkName: "pages/marketplace/offers" */))
const _1cc59230 = () => interopDefault(import('../pages/media_center.vue' /* webpackChunkName: "pages/media_center" */))
const _1cceef6b = () => interopDefault(import('../pages/media_center/index.vue' /* webpackChunkName: "pages/media_center/index" */))
const _4e09a5b4 = () => interopDefault(import('../pages/media_center/articles.vue' /* webpackChunkName: "pages/media_center/articles" */))
const _30af2923 = () => interopDefault(import('../pages/megastudio/index.vue' /* webpackChunkName: "pages/megastudio/index" */))
const _58d86822 = () => interopDefault(import('../pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _bde5399c = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _1a69f566 = () => interopDefault(import('../pages/offers/companys.vue' /* webpackChunkName: "pages/offers/companys" */))
const _36396cde = () => interopDefault(import('../pages/offers/discount.vue' /* webpackChunkName: "pages/offers/discount" */))
const _25e38d88 = () => interopDefault(import('../pages/offers/free.vue' /* webpackChunkName: "pages/offers/free" */))
const _9b574200 = () => interopDefault(import('../pages/offers/new.vue' /* webpackChunkName: "pages/offers/new" */))
const _3210808e = () => interopDefault(import('../pages/offers/popular.vue' /* webpackChunkName: "pages/offers/popular" */))
const _0d4d0a32 = () => interopDefault(import('../pages/recommendations.vue' /* webpackChunkName: "pages/recommendations" */))
const _f2bcb996 = () => interopDefault(import('../pages/recommendations/index.vue' /* webpackChunkName: "pages/recommendations/index" */))
const _5f61f7c6 = () => interopDefault(import('../pages/recommendations/events.vue' /* webpackChunkName: "pages/recommendations/events" */))
const _2e58d944 = () => interopDefault(import('../pages/recommendations/offers.vue' /* webpackChunkName: "pages/recommendations/offers" */))
const _0636925e = () => interopDefault(import('../pages/recommendations/video.vue' /* webpackChunkName: "pages/recommendations/video" */))
const _6eea71aa = () => interopDefault(import('../pages/recommendations/mixins/products.js' /* webpackChunkName: "pages/recommendations/mixins/products" */))
const _5454ab16 = () => interopDefault(import('../pages/trend.vue' /* webpackChunkName: "pages/trend" */))
const _f3187590 = () => interopDefault(import('../pages/trend/index.vue' /* webpackChunkName: "pages/trend/index" */))
const _75f581bb = () => interopDefault(import('../pages/trend/index/index.vue' /* webpackChunkName: "pages/trend/index/index" */))
const _7edf07c5 = () => interopDefault(import('../pages/trend/index/day.vue' /* webpackChunkName: "pages/trend/index/day" */))
const _7129a9ca = () => interopDefault(import('../pages/trend/index/week.vue' /* webpackChunkName: "pages/trend/index/week" */))
const _59f4f3a4 = () => interopDefault(import('../pages/trend/authors.vue' /* webpackChunkName: "pages/trend/authors" */))
const _59179b9e = () => interopDefault(import('../pages/trend/authors/index.vue' /* webpackChunkName: "pages/trend/authors/index" */))
const _8bc9448a = () => interopDefault(import('../pages/trend/authors/day.vue' /* webpackChunkName: "pages/trend/authors/day" */))
const _188cd836 = () => interopDefault(import('../pages/trend/authors/week.vue' /* webpackChunkName: "pages/trend/authors/week" */))
const _9e3e42b2 = () => interopDefault(import('../pages/trend/broadcast.vue' /* webpackChunkName: "pages/trend/broadcast" */))
const _7380e82c = () => interopDefault(import('../pages/trend/broadcast/index.vue' /* webpackChunkName: "pages/trend/broadcast/index" */))
const _1133d1b4 = () => interopDefault(import('../pages/trend/broadcast/day.vue' /* webpackChunkName: "pages/trend/broadcast/day" */))
const _7faf9f0c = () => interopDefault(import('../pages/trend/broadcast/week.vue' /* webpackChunkName: "pages/trend/broadcast/week" */))
const _c059e4c0 = () => interopDefault(import('../pages/trend/companys.vue' /* webpackChunkName: "pages/trend/companys" */))
const _0a46dc23 = () => interopDefault(import('../pages/trend/companys/index.vue' /* webpackChunkName: "pages/trend/companys/index" */))
const _65d847a6 = () => interopDefault(import('../pages/trend/companys/day.vue' /* webpackChunkName: "pages/trend/companys/day" */))
const _3fd0e3b3 = () => interopDefault(import('../pages/trend/companys/week.vue' /* webpackChunkName: "pages/trend/companys/week" */))
const _180cf58b = () => interopDefault(import('../pages/trend/telegram.vue' /* webpackChunkName: "pages/trend/telegram" */))
const _028fe7ce = () => interopDefault(import('../pages/trend/telegram/index.vue' /* webpackChunkName: "pages/trend/telegram/index" */))
const _44f1d698 = () => interopDefault(import('../pages/trend/telegram/day.vue' /* webpackChunkName: "pages/trend/telegram/day" */))
const _789b92b0 = () => interopDefault(import('../pages/trend/telegram/week.vue' /* webpackChunkName: "pages/trend/telegram/week" */))
const _0608b461 = () => interopDefault(import('../pages/trend/video.vue' /* webpackChunkName: "pages/trend/video" */))
const _60baae24 = () => interopDefault(import('../pages/trend/video/index.vue' /* webpackChunkName: "pages/trend/video/index" */))
const _3ea63a6e = () => interopDefault(import('../pages/trend/video/day.vue' /* webpackChunkName: "pages/trend/video/day" */))
const _008a4d92 = () => interopDefault(import('../pages/trend/video/week.vue' /* webpackChunkName: "pages/trend/video/week" */))
const _237a74db = () => interopDefault(import('../pages/about/advertisement.vue' /* webpackChunkName: "pages/about/advertisement" */))
const _3ae24e4c = () => interopDefault(import('../pages/about/business.vue' /* webpackChunkName: "pages/about/business" */))
const _45c2a2ae = () => interopDefault(import('../pages/about/extensions.vue' /* webpackChunkName: "pages/about/extensions" */))
const _208eac05 = () => interopDefault(import('../pages/megastudio/auditorium/index.vue' /* webpackChunkName: "pages/megastudio/auditorium/index" */))
const _1dfbb840 = () => interopDefault(import('../pages/megastudio/collection/index.vue' /* webpackChunkName: "pages/megastudio/collection/index" */))
const _beb4b7ca = () => interopDefault(import('../pages/megastudio/events/index.vue' /* webpackChunkName: "pages/megastudio/events/index" */))
const _3d985c4f = () => interopDefault(import('../pages/megastudio/evolution/index.vue' /* webpackChunkName: "pages/megastudio/evolution/index" */))
const _35d42f16 = () => interopDefault(import('../pages/megastudio/groups/index.vue' /* webpackChunkName: "pages/megastudio/groups/index" */))
const _784de4f7 = () => interopDefault(import('../pages/megastudio/lectures/index.vue' /* webpackChunkName: "pages/megastudio/lectures/index" */))
const _5ee5ee42 = () => interopDefault(import('../pages/megastudio/marketplace/index.vue' /* webpackChunkName: "pages/megastudio/marketplace/index" */))
const _4280ef19 = () => interopDefault(import('../pages/megastudio/offers/index.vue' /* webpackChunkName: "pages/megastudio/offers/index" */))
const _1dce4163 = () => interopDefault(import('../pages/megastudio/promo/index.vue' /* webpackChunkName: "pages/megastudio/promo/index" */))
const _55196f6d = () => interopDefault(import('../pages/megastudio/pulse/index.vue' /* webpackChunkName: "pages/megastudio/pulse/index" */))
const _17075e28 = () => interopDefault(import('../pages/megastudio/sector/index.vue' /* webpackChunkName: "pages/megastudio/sector/index" */))
const _337b1360 = () => interopDefault(import('../pages/megastudio/services/index.vue' /* webpackChunkName: "pages/megastudio/services/index" */))
const _dc26e636 = () => interopDefault(import('../pages/megastudio/settings/index.vue' /* webpackChunkName: "pages/megastudio/settings/index" */))
const _329f8425 = () => interopDefault(import('../pages/megastudio/statistics/index.vue' /* webpackChunkName: "pages/megastudio/statistics/index" */))
const _49057dc2 = () => interopDefault(import('../pages/megastudio/stream/index.vue' /* webpackChunkName: "pages/megastudio/stream/index" */))
const _a999344c = () => interopDefault(import('../pages/megastudio/vacancys/index.vue' /* webpackChunkName: "pages/megastudio/vacancys/index" */))
const _6a0f9462 = () => interopDefault(import('../pages/megastudio/video/index.vue' /* webpackChunkName: "pages/megastudio/video/index" */))
const _7b8f07e8 = () => interopDefault(import('../pages/navigator/authors.vue' /* webpackChunkName: "pages/navigator/authors" */))
const _0879c326 = () => interopDefault(import('../pages/navigator/companys.vue' /* webpackChunkName: "pages/navigator/companys" */))
const _2094ac18 = () => interopDefault(import('../pages/navigator/products.vue' /* webpackChunkName: "pages/navigator/products" */))
const _104fe977 = () => interopDefault(import('../pages/navigator/products/index.vue' /* webpackChunkName: "pages/navigator/products/index" */))
const _0e9db285 = () => interopDefault(import('../pages/navigator/products/new.vue' /* webpackChunkName: "pages/navigator/products/new" */))
const _4b19497c = () => interopDefault(import('../pages/navigator/products/offers.vue' /* webpackChunkName: "pages/navigator/products/offers" */))
const _9be369da = () => interopDefault(import('../pages/navigator/streams.vue' /* webpackChunkName: "pages/navigator/streams" */))
const _38661154 = () => interopDefault(import('../pages/navigator/streams/index.vue' /* webpackChunkName: "pages/navigator/streams/index" */))
const _791aa7b8 = () => interopDefault(import('../pages/navigator/streams/new.vue' /* webpackChunkName: "pages/navigator/streams/new" */))
const _46a7b185 = () => interopDefault(import('../pages/navigator/streams/collection/_url.vue' /* webpackChunkName: "pages/navigator/streams/collection/_url" */))
const _631f70b0 = () => interopDefault(import('../pages/navigator/users.vue' /* webpackChunkName: "pages/navigator/users" */))
const _eaaedd20 = () => interopDefault(import('../pages/recovery/password/index.vue' /* webpackChunkName: "pages/recovery/password/index" */))
const _157633ec = () => interopDefault(import('../pages/user/cabinet.vue' /* webpackChunkName: "pages/user/cabinet" */))
const _5acf196f = () => interopDefault(import('../pages/user/cabinet/index.vue' /* webpackChunkName: "pages/user/cabinet/index" */))
const _4e9bb092 = () => interopDefault(import('../pages/user/cabinet/newsletters.vue' /* webpackChunkName: "pages/user/cabinet/newsletters" */))
const _ac770e9a = () => interopDefault(import('../pages/user/cabinet/security.vue' /* webpackChunkName: "pages/user/cabinet/security" */))
const _13e64a0e = () => interopDefault(import('../pages/user/drafts.vue' /* webpackChunkName: "pages/user/drafts" */))
const _d62ae3de = () => interopDefault(import('../pages/user/drafts/index.vue' /* webpackChunkName: "pages/user/drafts/index" */))
const _948f23ba = () => interopDefault(import('../pages/user/drafts/broadcasts.vue' /* webpackChunkName: "pages/user/drafts/broadcasts" */))
const _dd5b5c20 = () => interopDefault(import('../pages/user/drafts/deferred.vue' /* webpackChunkName: "pages/user/drafts/deferred" */))
const _1a36686a = () => interopDefault(import('../pages/user/drafts/events.vue' /* webpackChunkName: "pages/user/drafts/events" */))
const _182c1a95 = () => interopDefault(import('../pages/user/drafts/goods.vue' /* webpackChunkName: "pages/user/drafts/goods" */))
const _2b404dea = () => interopDefault(import('../pages/user/drafts/link.vue' /* webpackChunkName: "pages/user/drafts/link" */))
const _2da56c30 = () => interopDefault(import('../pages/user/drafts/offers.vue' /* webpackChunkName: "pages/user/drafts/offers" */))
const _556cbff2 = () => interopDefault(import('../pages/user/drafts/streams.vue' /* webpackChunkName: "pages/user/drafts/streams" */))
const _4923d212 = () => interopDefault(import('../pages/user/drafts/telegram.vue' /* webpackChunkName: "pages/user/drafts/telegram" */))
const _044138e9 = () => interopDefault(import('../pages/user/drafts/vacancys.vue' /* webpackChunkName: "pages/user/drafts/vacancys" */))
const _147f7d3a = () => interopDefault(import('../pages/user/drafts/video.vue' /* webpackChunkName: "pages/user/drafts/video" */))
const _5dd47d28 = () => interopDefault(import('../pages/user/lists/index.vue' /* webpackChunkName: "pages/user/lists/index" */))
const _6a3b9aeb = () => interopDefault(import('../pages/user/messages/index.vue' /* webpackChunkName: "pages/user/messages/index" */))
const _1c58c00e = () => interopDefault(import('../pages/megastudio/auditorium/image.vue' /* webpackChunkName: "pages/megastudio/auditorium/image" */))
const _2bf676df = () => interopDefault(import('../pages/megastudio/auditorium/popup.vue' /* webpackChunkName: "pages/megastudio/auditorium/popup" */))
const _789d7f9e = () => interopDefault(import('../pages/megastudio/auditorium/telegram.vue' /* webpackChunkName: "pages/megastudio/auditorium/telegram" */))
const _95e216be = () => interopDefault(import('../pages/megastudio/auditorium/widget.vue' /* webpackChunkName: "pages/megastudio/auditorium/widget" */))
const _ff19f6ec = () => interopDefault(import('../pages/newsletter/unsubscribe/fail.vue' /* webpackChunkName: "pages/newsletter/unsubscribe/fail" */))
const _b4928272 = () => interopDefault(import('../pages/newsletter/unsubscribe/success.vue' /* webpackChunkName: "pages/newsletter/unsubscribe/success" */))
const _e52a27ba = () => interopDefault(import('../pages/place/broadcast/configuration/_id.vue' /* webpackChunkName: "pages/place/broadcast/configuration/_id" */))
const _420e6b6d = () => interopDefault(import('../pages/place/broadcast/confirm/_id.vue' /* webpackChunkName: "pages/place/broadcast/confirm/_id" */))
const _d2281fb4 = () => interopDefault(import('../pages/place/broadcast/content/_id.vue' /* webpackChunkName: "pages/place/broadcast/content/_id" */))
const _7c98cb88 = () => interopDefault(import('../pages/place/broadcast/settings/_id.vue' /* webpackChunkName: "pages/place/broadcast/settings/_id" */))
const _e8696df4 = () => interopDefault(import('../pages/place/broadcast/social/_id.vue' /* webpackChunkName: "pages/place/broadcast/social/_id" */))
const _4094393c = () => interopDefault(import('../pages/place/event/configuration/_id.vue' /* webpackChunkName: "pages/place/event/configuration/_id" */))
const _2d19bf46 = () => interopDefault(import('../pages/place/event/confirm/_id.vue' /* webpackChunkName: "pages/place/event/confirm/_id" */))
const _fc117802 = () => interopDefault(import('../pages/place/event/content/_id.vue' /* webpackChunkName: "pages/place/event/content/_id" */))
const _3812c183 = () => interopDefault(import('../pages/place/event/settings/_id.vue' /* webpackChunkName: "pages/place/event/settings/_id" */))
const _3889960d = () => interopDefault(import('../pages/place/event/social/_id.vue' /* webpackChunkName: "pages/place/event/social/_id" */))
const _a4322e10 = () => interopDefault(import('../pages/place/goods/configuration/_id.vue' /* webpackChunkName: "pages/place/goods/configuration/_id" */))
const _09ac27fc = () => interopDefault(import('../pages/place/goods/confirm/_id.vue' /* webpackChunkName: "pages/place/goods/confirm/_id" */))
const _500770bb = () => interopDefault(import('../pages/place/goods/content/_id.vue' /* webpackChunkName: "pages/place/goods/content/_id" */))
const _2c082c47 = () => interopDefault(import('../pages/place/goods/settings/_id.vue' /* webpackChunkName: "pages/place/goods/settings/_id" */))
const _0da2e1d1 = () => interopDefault(import('../pages/place/goods/social/_id.vue' /* webpackChunkName: "pages/place/goods/social/_id" */))
const _134b2edc = () => interopDefault(import('../pages/place/post/confirm/_id.vue' /* webpackChunkName: "pages/place/post/confirm/_id" */))
const _6828b395 = () => interopDefault(import('../pages/place/post/content/_id.vue' /* webpackChunkName: "pages/place/post/content/_id" */))
const _180f44ad = () => interopDefault(import('../pages/place/post/settings/_id.vue' /* webpackChunkName: "pages/place/post/settings/_id" */))
const _6940dbb7 = () => interopDefault(import('../pages/place/post/social/_id.vue' /* webpackChunkName: "pages/place/post/social/_id" */))
const _c9fa305e = () => interopDefault(import('../pages/place/product/configuration/_id.vue' /* webpackChunkName: "pages/place/product/configuration/_id" */))
const _7180419b = () => interopDefault(import('../pages/place/product/confirm/_id.vue' /* webpackChunkName: "pages/place/product/confirm/_id" */))
const _73447358 = () => interopDefault(import('../pages/place/product/content/_id.vue' /* webpackChunkName: "pages/place/product/content/_id" */))
const _ff06ec64 = () => interopDefault(import('../pages/place/product/settings/_id.vue' /* webpackChunkName: "pages/place/product/settings/_id" */))
const _7ccef518 = () => interopDefault(import('../pages/place/product/social/_id.vue' /* webpackChunkName: "pages/place/product/social/_id" */))
const _693b859c = () => interopDefault(import('../pages/place/stream/configuration/_id.vue' /* webpackChunkName: "pages/place/stream/configuration/_id" */))
const _9e860f16 = () => interopDefault(import('../pages/place/stream/content/_id.vue' /* webpackChunkName: "pages/place/stream/content/_id" */))
const _3bf8c866 = () => interopDefault(import('../pages/place/stream/settings/_id.vue' /* webpackChunkName: "pages/place/stream/settings/_id" */))
const _e6bf0a52 = () => interopDefault(import('../pages/place/stream/social/_id.vue' /* webpackChunkName: "pages/place/stream/social/_id" */))
const _95643346 = () => interopDefault(import('../pages/place/vacancy/configuration/_id.vue' /* webpackChunkName: "pages/place/vacancy/configuration/_id" */))
const _353fcdb2 = () => interopDefault(import('../pages/place/vacancy/confirm/_id.vue' /* webpackChunkName: "pages/place/vacancy/confirm/_id" */))
const _3a3d9de0 = () => interopDefault(import('../pages/place/vacancy/content/_id.vue' /* webpackChunkName: "pages/place/vacancy/content/_id" */))
const _eed0b87c = () => interopDefault(import('../pages/place/vacancy/settings/_id.vue' /* webpackChunkName: "pages/place/vacancy/settings/_id" */))
const _dde007e8 = () => interopDefault(import('../pages/place/vacancy/social/_id.vue' /* webpackChunkName: "pages/place/vacancy/social/_id" */))
const _142501b2 = () => interopDefault(import('../pages/place/video/confirm/_id.vue' /* webpackChunkName: "pages/place/video/confirm/_id" */))
const _4acb03e0 = () => interopDefault(import('../pages/place/video/content/_id.vue' /* webpackChunkName: "pages/place/video/content/_id" */))
const _ec92047c = () => interopDefault(import('../pages/place/video/settings/_id.vue' /* webpackChunkName: "pages/place/video/settings/_id" */))
const _263dd60c = () => interopDefault(import('../pages/place/video/social/_id.vue' /* webpackChunkName: "pages/place/video/social/_id" */))
const _ad3dddae = () => interopDefault(import('../pages/confirm/mail/_id.vue' /* webpackChunkName: "pages/confirm/mail/_id" */))
const _54c61c15 = () => interopDefault(import('../pages/megastudio/profile/_url.vue' /* webpackChunkName: "pages/megastudio/profile/_url" */))
const _6d0c2fd8 = () => interopDefault(import('../pages/recovery/password/_id.vue' /* webpackChunkName: "pages/recovery/password/_id" */))
const _a1bb0556 = () => interopDefault(import('../pages/broadcasts/_id.vue' /* webpackChunkName: "pages/broadcasts/_id" */))
const _7fb921ee = () => interopDefault(import('../pages/community/_url.vue' /* webpackChunkName: "pages/community/_url" */))
const _0073fdf1 = () => interopDefault(import('../pages/community/_url/index.vue' /* webpackChunkName: "pages/community/_url/index" */))
const _da427d24 = () => interopDefault(import('../pages/community/_url/articles.vue' /* webpackChunkName: "pages/community/_url/articles" */))
const _000c2b7a = () => interopDefault(import('../pages/community/_url/broadcasts.vue' /* webpackChunkName: "pages/community/_url/broadcasts" */))
const _1fdaf48a = () => interopDefault(import('../pages/community/_url/events.vue' /* webpackChunkName: "pages/community/_url/events" */))
const _17b0b368 = () => interopDefault(import('../pages/community/_url/experts.vue' /* webpackChunkName: "pages/community/_url/experts" */))
const _20730beb = () => interopDefault(import('../pages/community/_url/experts/index.vue' /* webpackChunkName: "pages/community/_url/experts/index" */))
const _7197660d = () => interopDefault(import('../pages/community/_url/experts/companys.vue' /* webpackChunkName: "pages/community/_url/experts/companys" */))
const _65dce621 = () => interopDefault(import('../pages/community/_url/experts/users.vue' /* webpackChunkName: "pages/community/_url/experts/users" */))
const _225c53f0 = () => interopDefault(import('../pages/community/_url/offers.vue' /* webpackChunkName: "pages/community/_url/offers" */))
const _0459b7d2 = () => interopDefault(import('../pages/community/_url/streams.vue' /* webpackChunkName: "pages/community/_url/streams" */))
const _ffee25cc = () => interopDefault(import('../pages/community/_url/video.vue' /* webpackChunkName: "pages/community/_url/video" */))
const _2566c142 = () => interopDefault(import('../pages/offer/_id.vue' /* webpackChunkName: "pages/offer/_id" */))
const _aa22afba = () => interopDefault(import('../pages/post/_id.vue' /* webpackChunkName: "pages/post/_id" */))
const _46e39ba8 = () => interopDefault(import('../pages/product/_url.vue' /* webpackChunkName: "pages/product/_url" */))
const _fd3277aa = () => interopDefault(import('../pages/product/_url/index.vue' /* webpackChunkName: "pages/product/_url/index" */))
const _6ec898f4 = () => interopDefault(import('../pages/product/_url/articles.vue' /* webpackChunkName: "pages/product/_url/articles" */))
const _ee680f6e = () => interopDefault(import('../pages/product/_url/broadcasts.vue' /* webpackChunkName: "pages/product/_url/broadcasts" */))
const _42616595 = () => interopDefault(import('../pages/product/_url/description.vue' /* webpackChunkName: "pages/product/_url/description" */))
const _2c95cf6f = () => interopDefault(import('../pages/product/_url/images.vue' /* webpackChunkName: "pages/product/_url/images" */))
const _e79051e4 = () => interopDefault(import('../pages/product/_url/offers.vue' /* webpackChunkName: "pages/product/_url/offers" */))
const _725ec33e = () => interopDefault(import('../pages/product/_url/users.vue' /* webpackChunkName: "pages/product/_url/users" */))
const _bb29cfb8 = () => interopDefault(import('../pages/product/_url/users/index.vue' /* webpackChunkName: "pages/product/_url/users/index" */))
const _122dc334 = () => interopDefault(import('../pages/product/_url/users/companys.vue' /* webpackChunkName: "pages/product/_url/users/companys" */))
const _30561b4c = () => interopDefault(import('../pages/product/_url/users/users.vue' /* webpackChunkName: "pages/product/_url/users/users" */))
const _00fbb354 = () => interopDefault(import('../pages/product/_url/video.vue' /* webpackChunkName: "pages/product/_url/video" */))
const _3c6fd505 = () => interopDefault(import('../pages/register/_type.vue' /* webpackChunkName: "pages/register/_type" */))
const _1df4cdb7 = () => interopDefault(import('../pages/stream/_url.vue' /* webpackChunkName: "pages/stream/_url" */))
const _18c7eefa = () => interopDefault(import('../pages/stream/_url/index.vue' /* webpackChunkName: "pages/stream/_url/index" */))
const _72d036da = () => interopDefault(import('../pages/stream/_url/broadcasts.vue' /* webpackChunkName: "pages/stream/_url/broadcasts" */))
const _ce360db8 = () => interopDefault(import('../pages/stream/_url/description.vue' /* webpackChunkName: "pages/stream/_url/description" */))
const _120524a1 = () => interopDefault(import('../pages/stream/_url/events.vue' /* webpackChunkName: "pages/stream/_url/events" */))
const _6acc8631 = () => interopDefault(import('../pages/stream/_url/experts.vue' /* webpackChunkName: "pages/stream/_url/experts" */))
const _4ea43818 = () => interopDefault(import('../pages/stream/_url/experts/index.vue' /* webpackChunkName: "pages/stream/_url/experts/index" */))
const _7951a564 = () => interopDefault(import('../pages/stream/_url/experts/companys.vue' /* webpackChunkName: "pages/stream/_url/experts/companys" */))
const _1e17be2a = () => interopDefault(import('../pages/stream/_url/experts/users.vue' /* webpackChunkName: "pages/stream/_url/experts/users" */))
const _fd4c0300 = () => interopDefault(import('../pages/stream/_url/images.vue' /* webpackChunkName: "pages/stream/_url/images" */))
const _3b1e0154 = () => interopDefault(import('../pages/stream/_url/materials.vue' /* webpackChunkName: "pages/stream/_url/materials" */))
const _423138d7 = () => interopDefault(import('../pages/stream/_url/materials/index.vue' /* webpackChunkName: "pages/stream/_url/materials/index" */))
const _c8822ce8 = () => interopDefault(import('../pages/stream/_url/materials/telegram.vue' /* webpackChunkName: "pages/stream/_url/materials/telegram" */))
const _3e07f3c2 = () => interopDefault(import('../pages/stream/_url/offers.vue' /* webpackChunkName: "pages/stream/_url/offers" */))
const _524a16b3 = () => interopDefault(import('../pages/stream/_url/subscribers.vue' /* webpackChunkName: "pages/stream/_url/subscribers" */))
const _cf4643ba = () => interopDefault(import('../pages/stream/_url/video.vue' /* webpackChunkName: "pages/stream/_url/video" */))
const _d78fa1ac = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _dea9a3a6 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _2d8a8418 = () => interopDefault(import('../pages/index/i/events.vue' /* webpackChunkName: "pages/index/i/events" */))
const _3fe1ebcb = () => interopDefault(import('../pages/index/i/goods.vue' /* webpackChunkName: "pages/index/i/goods" */))
const _8f9cc11c = () => interopDefault(import('../pages/index/i/offers.vue' /* webpackChunkName: "pages/index/i/offers" */))
const _3c354e70 = () => interopDefault(import('../pages/index/i/video.vue' /* webpackChunkName: "pages/index/i/video" */))
const _e6ed014e = () => interopDefault(import('../pages/index/i/mixins/products.js' /* webpackChunkName: "pages/index/i/mixins/products" */))
const _3d2b4902 = () => interopDefault(import('../pages/_author.vue' /* webpackChunkName: "pages/_author" */))
const _d52cf3f6 = () => interopDefault(import('../pages/_author/index.vue' /* webpackChunkName: "pages/_author/index" */))
const _e0bf701c = () => interopDefault(import('../pages/_author/accounting.vue' /* webpackChunkName: "pages/_author/accounting" */))
const _0ecf4b35 = () => interopDefault(import('../pages/_author/archive.vue' /* webpackChunkName: "pages/_author/archive" */))
const _1b3c5410 = () => interopDefault(import('../pages/_author/archive/index.vue' /* webpackChunkName: "pages/_author/archive/index" */))
const _75a3627c = () => interopDefault(import('../pages/_author/archive/goods.vue' /* webpackChunkName: "pages/_author/archive/goods" */))
const _3a9efee1 = () => interopDefault(import('../pages/_author/archive/offers.vue' /* webpackChunkName: "pages/_author/archive/offers" */))
const _71f6c521 = () => interopDefault(import('../pages/_author/archive/video.vue' /* webpackChunkName: "pages/_author/archive/video" */))
const _715a4eaf = () => interopDefault(import('../pages/_author/broadcasts.vue' /* webpackChunkName: "pages/_author/broadcasts" */))
const _2ad171df = () => interopDefault(import('../pages/_author/calculator.vue' /* webpackChunkName: "pages/_author/calculator" */))
const _10d5360e = () => interopDefault(import('../pages/_author/events/index.vue' /* webpackChunkName: "pages/_author/events/index" */))
const _3f97ce4c = () => interopDefault(import('../pages/_author/goods/index.vue' /* webpackChunkName: "pages/_author/goods/index" */))
const _6091ca42 = () => interopDefault(import('../pages/_author/materials.vue' /* webpackChunkName: "pages/_author/materials" */))
const _74d5be22 = () => interopDefault(import('../pages/_author/materials/index.vue' /* webpackChunkName: "pages/_author/materials/index" */))
const _8ab41c24 = () => interopDefault(import('../pages/_author/materials/exclusive.vue' /* webpackChunkName: "pages/_author/materials/exclusive" */))
const _199b473e = () => interopDefault(import('../pages/_author/materials/telegram.vue' /* webpackChunkName: "pages/_author/materials/telegram" */))
const _0ee55f18 = () => interopDefault(import('../pages/_author/offers.vue' /* webpackChunkName: "pages/_author/offers" */))
const _17b7e410 = () => interopDefault(import('../pages/_author/recommendations/index.vue' /* webpackChunkName: "pages/_author/recommendations/index" */))
const _bd25c2da = () => interopDefault(import('../pages/_author/recommendations/index/index.vue' /* webpackChunkName: "pages/_author/recommendations/index/index" */))
const _2ed03632 = () => interopDefault(import('../pages/_author/recommendations/index/provided.vue' /* webpackChunkName: "pages/_author/recommendations/index/provided" */))
const _4ebb519f = () => interopDefault(import('../pages/_author/skills.vue' /* webpackChunkName: "pages/_author/skills" */))
const _2502f97e = () => interopDefault(import('../pages/_author/subscribers.vue' /* webpackChunkName: "pages/_author/subscribers" */))
const _22cca9ae = () => interopDefault(import('../pages/_author/subscriptions.vue' /* webpackChunkName: "pages/_author/subscriptions" */))
const _2e196eec = () => interopDefault(import('../pages/_author/subscriptions/index.vue' /* webpackChunkName: "pages/_author/subscriptions/index" */))
const _319ad128 = () => interopDefault(import('../pages/_author/subscriptions/companys.vue' /* webpackChunkName: "pages/_author/subscriptions/companys" */))
const _5e66d60d = () => interopDefault(import('../pages/_author/subscriptions/streams.vue' /* webpackChunkName: "pages/_author/subscriptions/streams" */))
const _73834922 = () => interopDefault(import('../pages/_author/subscriptions/users.vue' /* webpackChunkName: "pages/_author/subscriptions/users" */))
const _05366738 = () => interopDefault(import('../pages/_author/vacancys/index.vue' /* webpackChunkName: "pages/_author/vacancys/index" */))
const _14fe752e = () => interopDefault(import('../pages/_author/video.vue' /* webpackChunkName: "pages/_author/video" */))
const _60709456 = () => interopDefault(import('../pages/_author/welcome.vue' /* webpackChunkName: "pages/_author/welcome" */))
const _1cfa51a1 = () => interopDefault(import('../pages/_author/events/_id.vue' /* webpackChunkName: "pages/_author/events/_id" */))
const _631cf3b4 = () => interopDefault(import('../pages/_author/goods/_id.vue' /* webpackChunkName: "pages/_author/goods/_id" */))
const _25e0ab10 = () => interopDefault(import('../pages/_author/recommendations/_id.vue' /* webpackChunkName: "pages/_author/recommendations/_id" */))
const _0b24d7a0 = () => interopDefault(import('../pages/_author/vacancys/_id.vue' /* webpackChunkName: "pages/_author/vacancys/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'base-active-link',
  linkExactActiveClass: 'base-exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _dad989b0,
    name: "about"
  }, {
    path: "/broadcasts",
    component: _bf3d18a6,
    children: [{
      path: "",
      component: _53a1c470,
      name: "broadcasts-index"
    }, {
      path: "live",
      component: _061b12be,
      name: "broadcasts-index-live"
    }, {
      path: "premiere",
      component: _9542306a,
      name: "broadcasts-index-premiere"
    }]
  }, {
    path: "/career",
    component: _2b2d774e,
    name: "career"
  }, {
    path: "/community",
    component: _7e582484,
    name: "community"
  }, {
    path: "/crm",
    component: _0306d839,
    name: "crm"
  }, {
    path: "/events",
    component: _049cea71,
    children: [{
      path: "",
      component: _019bcf98,
      name: "events"
    }, {
      path: "companys",
      component: _a7e3edb8,
      name: "events-companys"
    }, {
      path: "discount",
      component: _76875d4f,
      name: "events-discount"
    }, {
      path: "free",
      component: _08dddbfa,
      name: "events-free"
    }, {
      path: "nearest",
      component: _5efd2f40,
      name: "events-nearest"
    }, {
      path: "popular",
      component: _4ec33c3b,
      name: "events-popular"
    }]
  }, {
    path: "/goods",
    component: _1779bbae,
    children: [{
      path: "",
      component: _0e4a47b1,
      name: "goods"
    }, {
      path: "new",
      component: _a3a35182,
      name: "goods-new"
    }, {
      path: "popular",
      component: _0ee4ff10,
      name: "goods-popular"
    }]
  }, {
    path: "/login",
    component: _cd0837f8,
    name: "login"
  }, {
    path: "/marketplace",
    component: _1ef09c23,
    children: [{
      path: "",
      component: _735b8734,
      name: "marketplace"
    }, {
      path: "goods",
      component: _4993c8ea,
      name: "marketplace-goods"
    }, {
      path: "offers",
      component: _3689339a,
      name: "marketplace-offers"
    }]
  }, {
    path: "/media_center",
    component: _1cc59230,
    children: [{
      path: "",
      component: _1cceef6b,
      name: "media_center"
    }, {
      path: "articles",
      component: _4e09a5b4,
      name: "media_center-articles"
    }]
  }, {
    path: "/megastudio",
    component: _30af2923,
    name: "megastudio"
  }, {
    path: "/offers",
    component: _58d86822,
    children: [{
      path: "",
      component: _bde5399c,
      name: "offers"
    }, {
      path: "companys",
      component: _1a69f566,
      name: "offers-companys"
    }, {
      path: "discount",
      component: _36396cde,
      name: "offers-discount"
    }, {
      path: "free",
      component: _25e38d88,
      name: "offers-free"
    }, {
      path: "new",
      component: _9b574200,
      name: "offers-new"
    }, {
      path: "popular",
      component: _3210808e,
      name: "offers-popular"
    }]
  }, {
    path: "/recommendations",
    component: _0d4d0a32,
    children: [{
      path: "",
      component: _f2bcb996,
      name: "recommendations"
    }, {
      path: "events",
      component: _5f61f7c6,
      name: "recommendations-events"
    }, {
      path: "offers",
      component: _2e58d944,
      name: "recommendations-offers"
    }, {
      path: "video",
      component: _0636925e,
      name: "recommendations-video"
    }, {
      path: "mixins/products",
      component: _6eea71aa,
      name: "recommendations-mixins-products"
    }]
  }, {
    path: "/trend",
    component: _5454ab16,
    children: [{
      path: "",
      component: _f3187590,
      children: [{
        path: "",
        component: _75f581bb,
        name: "trend-index"
      }, {
        path: "day",
        component: _7edf07c5,
        name: "trend-index-day"
      }, {
        path: "week",
        component: _7129a9ca,
        name: "trend-index-week"
      }]
    }, {
      path: "authors",
      component: _59f4f3a4,
      children: [{
        path: "",
        component: _59179b9e,
        name: "trend-authors"
      }, {
        path: "day",
        component: _8bc9448a,
        name: "trend-authors-day"
      }, {
        path: "week",
        component: _188cd836,
        name: "trend-authors-week"
      }]
    }, {
      path: "broadcast",
      component: _9e3e42b2,
      children: [{
        path: "",
        component: _7380e82c,
        name: "trend-broadcast"
      }, {
        path: "day",
        component: _1133d1b4,
        name: "trend-broadcast-day"
      }, {
        path: "week",
        component: _7faf9f0c,
        name: "trend-broadcast-week"
      }]
    }, {
      path: "companys",
      component: _c059e4c0,
      children: [{
        path: "",
        component: _0a46dc23,
        name: "trend-companys"
      }, {
        path: "day",
        component: _65d847a6,
        name: "trend-companys-day"
      }, {
        path: "week",
        component: _3fd0e3b3,
        name: "trend-companys-week"
      }]
    }, {
      path: "telegram",
      component: _180cf58b,
      children: [{
        path: "",
        component: _028fe7ce,
        name: "trend-telegram"
      }, {
        path: "day",
        component: _44f1d698,
        name: "trend-telegram-day"
      }, {
        path: "week",
        component: _789b92b0,
        name: "trend-telegram-week"
      }]
    }, {
      path: "video",
      component: _0608b461,
      children: [{
        path: "",
        component: _60baae24,
        name: "trend-video"
      }, {
        path: "day",
        component: _3ea63a6e,
        name: "trend-video-day"
      }, {
        path: "week",
        component: _008a4d92,
        name: "trend-video-week"
      }]
    }]
  }, {
    path: "/about/advertisement",
    component: _237a74db,
    name: "about-advertisement"
  }, {
    path: "/about/business",
    component: _3ae24e4c,
    name: "about-business"
  }, {
    path: "/about/extensions",
    component: _45c2a2ae,
    name: "about-extensions"
  }, {
    path: "/megastudio/auditorium",
    component: _208eac05,
    name: "megastudio-auditorium"
  }, {
    path: "/megastudio/collection",
    component: _1dfbb840,
    name: "megastudio-collection"
  }, {
    path: "/megastudio/events",
    component: _beb4b7ca,
    name: "megastudio-events"
  }, {
    path: "/megastudio/evolution",
    component: _3d985c4f,
    name: "megastudio-evolution"
  }, {
    path: "/megastudio/groups",
    component: _35d42f16,
    name: "megastudio-groups"
  }, {
    path: "/megastudio/lectures",
    component: _784de4f7,
    name: "megastudio-lectures"
  }, {
    path: "/megastudio/marketplace",
    component: _5ee5ee42,
    name: "megastudio-marketplace"
  }, {
    path: "/megastudio/offers",
    component: _4280ef19,
    name: "megastudio-offers"
  }, {
    path: "/megastudio/promo",
    component: _1dce4163,
    name: "megastudio-promo"
  }, {
    path: "/megastudio/pulse",
    component: _55196f6d,
    name: "megastudio-pulse"
  }, {
    path: "/megastudio/sector",
    component: _17075e28,
    name: "megastudio-sector"
  }, {
    path: "/megastudio/services",
    component: _337b1360,
    name: "megastudio-services"
  }, {
    path: "/megastudio/settings",
    component: _dc26e636,
    name: "megastudio-settings"
  }, {
    path: "/megastudio/statistics",
    component: _329f8425,
    name: "megastudio-statistics"
  }, {
    path: "/megastudio/stream",
    component: _49057dc2,
    name: "megastudio-stream"
  }, {
    path: "/megastudio/vacancys",
    component: _a999344c,
    name: "megastudio-vacancys"
  }, {
    path: "/megastudio/video",
    component: _6a0f9462,
    name: "megastudio-video"
  }, {
    path: "/navigator/authors",
    component: _7b8f07e8,
    name: "navigator-authors"
  }, {
    path: "/navigator/companys",
    component: _0879c326,
    name: "navigator-companys"
  }, {
    path: "/navigator/products",
    component: _2094ac18,
    children: [{
      path: "",
      component: _104fe977,
      name: "navigator-products"
    }, {
      path: "new",
      component: _0e9db285,
      name: "navigator-products-new"
    }, {
      path: "offers",
      component: _4b19497c,
      name: "navigator-products-offers"
    }]
  }, {
    path: "/navigator/streams",
    component: _9be369da,
    children: [{
      path: "",
      component: _38661154,
      name: "navigator-streams"
    }, {
      path: "new",
      component: _791aa7b8,
      name: "navigator-streams-new"
    }, {
      path: "collection/:url?",
      component: _46a7b185,
      name: "navigator-streams-collection-url"
    }]
  }, {
    path: "/navigator/users",
    component: _631f70b0,
    name: "navigator-users"
  }, {
    path: "/recovery/password",
    component: _eaaedd20,
    name: "recovery-password"
  }, {
    path: "/user/cabinet",
    component: _157633ec,
    children: [{
      path: "",
      component: _5acf196f,
      name: "user-cabinet"
    }, {
      path: "newsletters",
      component: _4e9bb092,
      name: "user-cabinet-newsletters"
    }, {
      path: "security",
      component: _ac770e9a,
      name: "user-cabinet-security"
    }]
  }, {
    path: "/user/drafts",
    component: _13e64a0e,
    children: [{
      path: "",
      component: _d62ae3de,
      name: "user-drafts"
    }, {
      path: "broadcasts",
      component: _948f23ba,
      name: "user-drafts-broadcasts"
    }, {
      path: "deferred",
      component: _dd5b5c20,
      name: "user-drafts-deferred"
    }, {
      path: "events",
      component: _1a36686a,
      name: "user-drafts-events"
    }, {
      path: "goods",
      component: _182c1a95,
      name: "user-drafts-goods"
    }, {
      path: "link",
      component: _2b404dea,
      name: "user-drafts-link"
    }, {
      path: "offers",
      component: _2da56c30,
      name: "user-drafts-offers"
    }, {
      path: "streams",
      component: _556cbff2,
      name: "user-drafts-streams"
    }, {
      path: "telegram",
      component: _4923d212,
      name: "user-drafts-telegram"
    }, {
      path: "vacancys",
      component: _044138e9,
      name: "user-drafts-vacancys"
    }, {
      path: "video",
      component: _147f7d3a,
      name: "user-drafts-video"
    }]
  }, {
    path: "/user/lists",
    component: _5dd47d28,
    name: "user-lists"
  }, {
    path: "/user/messages",
    component: _6a3b9aeb,
    name: "user-messages"
  }, {
    path: "/megastudio/auditorium/image",
    component: _1c58c00e,
    name: "megastudio-auditorium-image"
  }, {
    path: "/megastudio/auditorium/popup",
    component: _2bf676df,
    name: "megastudio-auditorium-popup"
  }, {
    path: "/megastudio/auditorium/telegram",
    component: _789d7f9e,
    name: "megastudio-auditorium-telegram"
  }, {
    path: "/megastudio/auditorium/widget",
    component: _95e216be,
    name: "megastudio-auditorium-widget"
  }, {
    path: "/newsletter/unsubscribe/fail",
    component: _ff19f6ec,
    name: "newsletter-unsubscribe-fail"
  }, {
    path: "/newsletter/unsubscribe/success",
    component: _b4928272,
    name: "newsletter-unsubscribe-success"
  }, {
    path: "/place/broadcast/configuration/:id?",
    component: _e52a27ba,
    name: "place-broadcast-configuration-id"
  }, {
    path: "/place/broadcast/confirm/:id?",
    component: _420e6b6d,
    name: "place-broadcast-confirm-id"
  }, {
    path: "/place/broadcast/content/:id?",
    component: _d2281fb4,
    name: "place-broadcast-content-id"
  }, {
    path: "/place/broadcast/settings/:id?",
    component: _7c98cb88,
    name: "place-broadcast-settings-id"
  }, {
    path: "/place/broadcast/social/:id?",
    component: _e8696df4,
    name: "place-broadcast-social-id"
  }, {
    path: "/place/event/configuration/:id?",
    component: _4094393c,
    name: "place-event-configuration-id"
  }, {
    path: "/place/event/confirm/:id?",
    component: _2d19bf46,
    name: "place-event-confirm-id"
  }, {
    path: "/place/event/content/:id?",
    component: _fc117802,
    name: "place-event-content-id"
  }, {
    path: "/place/event/settings/:id?",
    component: _3812c183,
    name: "place-event-settings-id"
  }, {
    path: "/place/event/social/:id?",
    component: _3889960d,
    name: "place-event-social-id"
  }, {
    path: "/place/goods/configuration/:id?",
    component: _a4322e10,
    name: "place-goods-configuration-id"
  }, {
    path: "/place/goods/confirm/:id?",
    component: _09ac27fc,
    name: "place-goods-confirm-id"
  }, {
    path: "/place/goods/content/:id?",
    component: _500770bb,
    name: "place-goods-content-id"
  }, {
    path: "/place/goods/settings/:id?",
    component: _2c082c47,
    name: "place-goods-settings-id"
  }, {
    path: "/place/goods/social/:id?",
    component: _0da2e1d1,
    name: "place-goods-social-id"
  }, {
    path: "/place/post/confirm/:id?",
    component: _134b2edc,
    name: "place-post-confirm-id"
  }, {
    path: "/place/post/content/:id?",
    component: _6828b395,
    name: "place-post-content-id"
  }, {
    path: "/place/post/settings/:id?",
    component: _180f44ad,
    name: "place-post-settings-id"
  }, {
    path: "/place/post/social/:id?",
    component: _6940dbb7,
    name: "place-post-social-id"
  }, {
    path: "/place/product/configuration/:id?",
    component: _c9fa305e,
    name: "place-product-configuration-id"
  }, {
    path: "/place/product/confirm/:id?",
    component: _7180419b,
    name: "place-product-confirm-id"
  }, {
    path: "/place/product/content/:id?",
    component: _73447358,
    name: "place-product-content-id"
  }, {
    path: "/place/product/settings/:id?",
    component: _ff06ec64,
    name: "place-product-settings-id"
  }, {
    path: "/place/product/social/:id?",
    component: _7ccef518,
    name: "place-product-social-id"
  }, {
    path: "/place/stream/configuration/:id?",
    component: _693b859c,
    name: "place-stream-configuration-id"
  }, {
    path: "/place/stream/content/:id?",
    component: _9e860f16,
    name: "place-stream-content-id"
  }, {
    path: "/place/stream/settings/:id?",
    component: _3bf8c866,
    name: "place-stream-settings-id"
  }, {
    path: "/place/stream/social/:id?",
    component: _e6bf0a52,
    name: "place-stream-social-id"
  }, {
    path: "/place/vacancy/configuration/:id?",
    component: _95643346,
    name: "place-vacancy-configuration-id"
  }, {
    path: "/place/vacancy/confirm/:id?",
    component: _353fcdb2,
    name: "place-vacancy-confirm-id"
  }, {
    path: "/place/vacancy/content/:id?",
    component: _3a3d9de0,
    name: "place-vacancy-content-id"
  }, {
    path: "/place/vacancy/settings/:id?",
    component: _eed0b87c,
    name: "place-vacancy-settings-id"
  }, {
    path: "/place/vacancy/social/:id?",
    component: _dde007e8,
    name: "place-vacancy-social-id"
  }, {
    path: "/place/video/confirm/:id?",
    component: _142501b2,
    name: "place-video-confirm-id"
  }, {
    path: "/place/video/content/:id?",
    component: _4acb03e0,
    name: "place-video-content-id"
  }, {
    path: "/place/video/settings/:id?",
    component: _ec92047c,
    name: "place-video-settings-id"
  }, {
    path: "/place/video/social/:id?",
    component: _263dd60c,
    name: "place-video-social-id"
  }, {
    path: "/confirm/mail/:id?",
    component: _ad3dddae,
    name: "confirm-mail-id"
  }, {
    path: "/megastudio/profile/:url?",
    component: _54c61c15,
    name: "megastudio-profile-url"
  }, {
    path: "/recovery/password/:id",
    component: _6d0c2fd8,
    name: "recovery-password-id"
  }, {
    path: "/broadcasts/:id",
    component: _a1bb0556,
    name: "broadcasts-id"
  }, {
    path: "/community/:url",
    component: _7fb921ee,
    children: [{
      path: "",
      component: _0073fdf1,
      name: "community-url"
    }, {
      path: "articles",
      component: _da427d24,
      name: "community-url-articles"
    }, {
      path: "broadcasts",
      component: _000c2b7a,
      name: "community-url-broadcasts"
    }, {
      path: "events",
      component: _1fdaf48a,
      name: "community-url-events"
    }, {
      path: "experts",
      component: _17b0b368,
      children: [{
        path: "",
        component: _20730beb,
        name: "community-url-experts"
      }, {
        path: "companys",
        component: _7197660d,
        name: "community-url-experts-companys"
      }, {
        path: "users",
        component: _65dce621,
        name: "community-url-experts-users"
      }]
    }, {
      path: "offers",
      component: _225c53f0,
      name: "community-url-offers"
    }, {
      path: "streams",
      component: _0459b7d2,
      name: "community-url-streams"
    }, {
      path: "video",
      component: _ffee25cc,
      name: "community-url-video"
    }]
  }, {
    path: "/offer/:id?",
    component: _2566c142,
    name: "offer-id"
  }, {
    path: "/post/:id?",
    component: _aa22afba,
    name: "post-id"
  }, {
    path: "/product/:url?",
    component: _46e39ba8,
    children: [{
      path: "",
      component: _fd3277aa,
      name: "product-url"
    }, {
      path: "articles",
      component: _6ec898f4,
      name: "product-url-articles"
    }, {
      path: "broadcasts",
      component: _ee680f6e,
      name: "product-url-broadcasts"
    }, {
      path: "description",
      component: _42616595,
      name: "product-url-description"
    }, {
      path: "images",
      component: _2c95cf6f,
      name: "product-url-images"
    }, {
      path: "offers",
      component: _e79051e4,
      name: "product-url-offers"
    }, {
      path: "users",
      component: _725ec33e,
      children: [{
        path: "",
        component: _bb29cfb8,
        name: "product-url-users"
      }, {
        path: "companys",
        component: _122dc334,
        name: "product-url-users-companys"
      }, {
        path: "users",
        component: _30561b4c,
        name: "product-url-users-users"
      }]
    }, {
      path: "video",
      component: _00fbb354,
      name: "product-url-video"
    }]
  }, {
    path: "/register/:type?",
    component: _3c6fd505,
    name: "register-type"
  }, {
    path: "/stream/:url?",
    component: _1df4cdb7,
    children: [{
      path: "",
      component: _18c7eefa,
      name: "stream-url"
    }, {
      path: "broadcasts",
      component: _72d036da,
      name: "stream-url-broadcasts"
    }, {
      path: "description",
      component: _ce360db8,
      name: "stream-url-description"
    }, {
      path: "events",
      component: _120524a1,
      name: "stream-url-events"
    }, {
      path: "experts",
      component: _6acc8631,
      children: [{
        path: "",
        component: _4ea43818,
        name: "stream-url-experts"
      }, {
        path: "companys",
        component: _7951a564,
        name: "stream-url-experts-companys"
      }, {
        path: "users",
        component: _1e17be2a,
        name: "stream-url-experts-users"
      }]
    }, {
      path: "images",
      component: _fd4c0300,
      name: "stream-url-images"
    }, {
      path: "materials",
      component: _3b1e0154,
      children: [{
        path: "",
        component: _423138d7,
        name: "stream-url-materials"
      }, {
        path: "telegram",
        component: _c8822ce8,
        name: "stream-url-materials-telegram"
      }]
    }, {
      path: "offers",
      component: _3e07f3c2,
      name: "stream-url-offers"
    }, {
      path: "subscribers",
      component: _524a16b3,
      name: "stream-url-subscribers"
    }, {
      path: "video",
      component: _cf4643ba,
      name: "stream-url-video"
    }]
  }, {
    path: "/",
    component: _d78fa1ac,
    children: [{
      path: "",
      component: _dea9a3a6,
      name: "index"
    }, {
      path: "i/events",
      component: _2d8a8418,
      name: "index-i-events"
    }, {
      path: "i/goods",
      component: _3fe1ebcb,
      name: "index-i-goods"
    }, {
      path: "i/offers",
      component: _8f9cc11c,
      name: "index-i-offers"
    }, {
      path: "i/video",
      component: _3c354e70,
      name: "index-i-video"
    }, {
      path: "i/mixins/products",
      component: _e6ed014e,
      name: "index-i-mixins-products"
    }]
  }, {
    path: "/:author",
    component: _3d2b4902,
    children: [{
      path: "",
      component: _d52cf3f6,
      name: "author"
    }, {
      path: "accounting",
      component: _e0bf701c,
      name: "author-accounting"
    }, {
      path: "archive",
      component: _0ecf4b35,
      children: [{
        path: "",
        component: _1b3c5410,
        name: "author-archive"
      }, {
        path: "goods",
        component: _75a3627c,
        name: "author-archive-goods"
      }, {
        path: "offers",
        component: _3a9efee1,
        name: "author-archive-offers"
      }, {
        path: "video",
        component: _71f6c521,
        name: "author-archive-video"
      }]
    }, {
      path: "broadcasts",
      component: _715a4eaf,
      name: "author-broadcasts"
    }, {
      path: "calculator",
      component: _2ad171df,
      name: "author-calculator"
    }, {
      path: "events",
      component: _10d5360e,
      name: "author-events"
    }, {
      path: "goods",
      component: _3f97ce4c,
      name: "author-goods"
    }, {
      path: "materials",
      component: _6091ca42,
      children: [{
        path: "",
        component: _74d5be22,
        name: "author-materials"
      }, {
        path: "exclusive",
        component: _8ab41c24,
        name: "author-materials-exclusive"
      }, {
        path: "telegram",
        component: _199b473e,
        name: "author-materials-telegram"
      }]
    }, {
      path: "offers",
      component: _0ee55f18,
      name: "author-offers"
    }, {
      path: "recommendations",
      component: _17b7e410,
      children: [{
        path: "",
        component: _bd25c2da,
        name: "author-recommendations-index"
      }, {
        path: "provided",
        component: _2ed03632,
        name: "author-recommendations-index-provided"
      }]
    }, {
      path: "skills",
      component: _4ebb519f,
      name: "author-skills"
    }, {
      path: "subscribers",
      component: _2502f97e,
      name: "author-subscribers"
    }, {
      path: "subscriptions",
      component: _22cca9ae,
      children: [{
        path: "",
        component: _2e196eec,
        name: "author-subscriptions"
      }, {
        path: "companys",
        component: _319ad128,
        name: "author-subscriptions-companys"
      }, {
        path: "streams",
        component: _5e66d60d,
        name: "author-subscriptions-streams"
      }, {
        path: "users",
        component: _73834922,
        name: "author-subscriptions-users"
      }]
    }, {
      path: "vacancys",
      component: _05366738,
      name: "author-vacancys"
    }, {
      path: "video",
      component: _14fe752e,
      name: "author-video"
    }, {
      path: "welcome",
      component: _60709456,
      name: "author-welcome"
    }, {
      path: "events/:id",
      component: _1cfa51a1,
      name: "author-events-id"
    }, {
      path: "goods/:id",
      component: _631cf3b4,
      name: "author-goods-id"
    }, {
      path: "recommendations/:id",
      component: _25e0ab10,
      name: "author-recommendations-id"
    }, {
      path: "vacancys/:id",
      component: _0b24d7a0,
      name: "author-vacancys-id"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
