import Vue from 'vue'

/**
 * Фильтр для форматирования числа (с пробелами по разрядам)
 */
export const format = function (value) {
  return value < 0
    ? `−${new Intl.NumberFormat('ru-Ru').format(value * -1)}`
    : new Intl.NumberFormat('ru-Ru').format(value)
}

Vue.filter('formatNumber', (value) => {
  if (!isNaN(value)) {
    return format(value)
  } else {
    const num = Number(value?.replace(/\s+/g, ''))
    return isNaN(num) ? value : format(num)
  }
})
