import { defineNuxtPlugin } from '@nuxtjs/composition-api'

const mainLinks = [
  {
    id: 1,
    title: 'Лента',
    icon: 'home',
    url: '/',
    private: false,
    forModerator: false,
  },
  {
    id: 3,
    title: 'Обзоры',
    icon: 'laptop',
    url: '/navigator/products',
    private: false,
    forModerator: false,
  },
  {
    id: 4,
    title: 'Навигатор',
    icon: 'navigation',
    url: '/navigator/companys',
    private: false,
    forModerator: false,
  },
  {
    id: 5,
    title: 'В тренде',
    icon: 'rocket',
    url: '/trend',
    private: false,
    forModerator: false,
  },
  {
    id: 6,
    title: 'Сообщества',
    icon: 'star',
    url: '/community',
    private: false,
    forModerator: false,
  },
  {
    id: 7,
    title: 'Трансляции',
    icon: 'play',
    url: '/broadcasts',
    private: false,
    forModerator: false,
  },
]
const userLinks = [
  {
    id: 1,
    title: 'Студия',
    icon: 'edit-full',
    url: '/megastudio',
  },
  {
    id: 2,
    title: 'Заказы',
    icon: 'box',
    url: '/crm',
    access: 'verified',
    sub: 'getCountOpenLeads',
  },
  {
    id: 3,
    title: 'Подписки',
    icon: 'following',
    url: '$subscriptions',
  },
  {
    id: 4,
    title: 'Черновики',
    icon: 'layers',
    url: '/user/drafts',
  },
]

export class Menu {
  private _store

  constructor(store: any) {
    this._store = store
  }

  get isVisible(): boolean {
    return this._store.getters['menu/getVisible']
  }

  get getCountOpenLeads(): number {
    return this._store.getters['menu/getCountOpenLeads']
  }

  get mainLinks() {
    return mainLinks
  }

  get userLinks() {
    return userLinks
  }

  get close() {
    return () => {
      this._store.commit('menu/SET_VISIBLE', false)
    }
  }

  get open() {
    return () => {
      this._store.commit('menu/SET_VISIBLE', true)
    }
  }

  get toggle() {
    return () => {
      this._store.commit('menu/TOGGLE_VISIBLE')
    }
  }
}

export default defineNuxtPlugin(({ store }, inject) => {
  inject('menu', new Menu(store))
})
