import Vue from 'vue'

/**
 * Фильтр для форматирования колличества (с добавлением текстовых разрядов)
 */
Vue.filter('formatCount', (value) => {
  const COUNT_FORMATS = [
    {
      letter: '',
      limit: 1e3,
    },
    {
      letter: '\u00A0тыс.',
      limit: 1e6,
    },
    {
      letter: '\u00A0млн.',
      limit: 1e9,
    },
    {
      letter: '\u00A0млрд.',
      limit: 1e12,
    },
    {
      letter: '\u00A0трлн.',
      limit: 1e15,
    },
  ]
  let num = !isNaN(value) ? Number(value) : Number(value?.replace(/\s+/g, ''))
  if (!isNaN(num)) {
    const format = COUNT_FORMATS.find((format) => Math.abs(num) < format.limit)
    num = (1000 * num) / format.limit

    return num < 0
      ? `−${(Math.ceil(num * 10) / 10) * -1}${format.letter}`
      : Math.floor(num * 10) / 10 + format.letter
  } else {
    return value
  }
})
