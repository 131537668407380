import { load } from '@fingerprintjs/fingerprintjs'
export default function ({ $auth, $dayjs, $axios }) {
  if (process.client) {
    if ($auth.loggedIn) {
      const timeZone = $dayjs.tz.guess(true)

      const now = $dayjs()

      const fpPromise = load()
      ;(async () => {
        const fp = await fpPromise
        const result = await fp.get()
        const fingerprint = result.visitorId
        $axios.$post('user/init/session', {
          timeZone,
          fingerprint,
          date: now,
        })
      })()
    }
  }
}
