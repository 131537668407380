import Vue from 'vue'

/**
 * Фильтр для форматирования номера телефона
 */
Vue.filter('formatPhone', (value) => {
  let val = String(value).replace(/\D/g, '')
  const matrix = '+7 (___) ___-__-__'
  let i = 0
  if (val[0] === '8') {
    const str = val.split('')
    str[0] = '7'
    val = str.join('')
  }
  value = matrix.replace(/[_\d]/g, function () {
    return val.charAt(i++)
  })
  return value
})
