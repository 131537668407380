export const state = () => ({
  tariffs: null,
  social: {
    channels: [],
  },
  modules: [],
  periods: [],
  services: [],
  gifts: [],
})

export const getters = {
  getTariffs: (state) => {
    return state.tariffs
  },
  getSocialChannels: (state) => {
    return state.social.channels
  },
  getModules: (state) => {
    return state.modules
  },
  getModuleById: (state) => (id) => {
    return state.modules.find((module) => module._id === id)
  },
  getServicesByModuleId: (state) => (id) => {
    return state.services.filter((service) => service.module === id)
  },
  getPeriods: (state) => {
    return state.periods
  },
  getServices: (state) => {
    return state.services
  },
  getGifts: (state) => {
    return state.gifts
  },
}

export const mutations = {
  SET_TARIFFS: (state, payload) => {
    state.tariffs = payload
  },
  SET_MODULES: (state, payload) => {
    state.modules = payload
  },
  SET_PERIODS: (state, payload) => {
    state.periods = payload
  },
  SET_SERVICES: (state, payload) => {
    state.services = payload
  },
  SET_GIFTS: (state, payload) => {
    state.gifts = payload
  },
  SET_SOCIAL_CHANNELS: (state, payload) => {
    state.social.channels = payload
  },
  PUSH_SOCIAL_CHANNEL: (state, payload) => {
    state.social.channels.push(payload)
  },
  UPDATE_SOCIAL_CHANNEL: (state, payload) => {
    state.social.channels.forEach((channel) => {
      if (channel._id === payload._id) {
        channel.title = payload.title
        channel.isShow = payload.isShow
        channel.avatar = payload.avatar
        channel.link.title = payload.link.title
        channel.link.url = payload.link.url
        channel.auditorium = payload.auditorium
      }
    })
  },
  PUSH_MODULES: (state, payload) => {
    state.modules.push(payload)
  },
  PUSH_SERVICES: (state, payload) => {
    state.services.push(payload)
  },
  PUSH_GIFTS: (state, payload) => {
    state.gifts.push(payload)
  },
  PUSH_PERIODS: (state, payload) => {
    if (payload.default) {
      state.periods.forEach((periodA) => {
        periodA.default = false
      })
    }
    state.periods.push(payload)
  },
  UPDATE_MODULES: (state, payload) => {
    state.modules.forEach((module) => {
      if (module._id === payload._id) {
        module.title = payload.title
        module.description = payload.description
      }
    })
  },
  UPDATE_SERVICES: (state, payload) => {
    state.services.forEach((service) => {
      if (service._id === payload._id) {
        service.title = payload.title
        service.description = payload.description
        service.price = payload.price
        service.oldPrice = payload.oldPrice
        service.status = payload.status
        service.blockCheck = payload.blockCheck
        service.module = payload.module
      }
    })
  },
  UPDATE_GIFTS: (state, payload) => {
    state.gifts.forEach((gift) => {
      if (gift._id === payload._id) {
        gift.title = payload.title
        gift.description = payload.description
        gift.inRegister = payload.inRegister
        gift.inConnection = payload.inConnection
        gift.inTariff = payload.inTariff
      }
    })
  },
  UPDATE_PERIODS: (state, payload) => {
    if (payload.default) {
      state.periods.forEach((periodA) => {
        periodA.default = false
      })
    }
    state.periods.forEach((period) => {
      if (period._id === payload._id) {
        period.title = payload.title
        period.period = payload.period
        period.discont = payload.discont
        period.default = payload.default
      }
    })
  },
  DELETE_PERIODS: (state, payload) => {
    state.periods = state.periods.filter((period) => period._id !== payload)
  },
  DELETE_MODULES: (state, payload) => {
    state.modules = state.modules.filter((module) => module._id !== payload)
  },
  DELETE_SERVICES: (state, payload) => {
    state.services = state.services.filter((service) => service._id !== payload)
  },
  DELETE_GIFTS: (state, payload) => {
    state.gifts = state.gifts.filter((gift) => gift._id !== payload)
  },
  DELETE_SOCIAL_CHANNEL: (state, payload) => {
    state.social.channels = state.social.channels.filter(
      (channel) => channel._id !== payload
    )
  },
  UPDATE_TARIFF: (state, payload) => {
    state.tariffs.forEach((tariff) => {
      if (tariff.uuid === payload.uuid) {
        tariff.title = payload.title
        tariff.description = payload.description
        tariff.price.cost = payload.price.cost
        tariff.price.quarterDiscont = payload.price.quarterDiscont
        tariff.price.sixMonthsDiscont = payload.price.sixMonthsDiscont
        tariff.price.yearDiscont = payload.price.yearDiscont
        tariff.benefits = payload.benefits
      }
    })
  },
}

export const actions = {
  async initSettings({ commit }) {
    const baseSettings = await this.$axios.$get('/settings/base')

    commit('SET_TARIFFS', baseSettings?.tariffs)
    commit('SET_SOCIAL_CHANNELS', baseSettings?.social?.channels)
    commit('SET_MODULES', baseSettings?.extentions?.modules)
    commit('SET_PERIODS', baseSettings?.extentions?.periods)
    commit('SET_SERVICES', baseSettings?.extentions?.services)
    commit('SET_GIFTS', baseSettings?.extentions?.gifts)
  },
}
