import Vue from 'vue'

export const format = function (value, symbol = 'руб.') {
  return value < 0
    ? `−${new Intl.NumberFormat('ru-Ru').format(value * -1)}\u00A0${symbol}`
    : `${new Intl.NumberFormat('ru-Ru').format(value)}\u00A0${symbol}`
}

/**
 * Фильтр для форматирования денег (символ рубля по умолчанию, но можно заменить передав параметром)
 */
Vue.filter('formatMoney', (value, symbol = 'руб.') => {
  if (!isNaN(value)) {
    return format(value, symbol)
  } else {
    const num = Number(value.replace(/\s+/g, ''))
    return isNaN(num) ? value : format(num, symbol)
  }
})
