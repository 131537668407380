export const state = () => ({
  isMenuVisible: false,
  countOpenLeads: 0,
})

export const getters = {
  getVisible: (state) => {
    return state.isMenuVisible
  },
  getCountOpenLeads: (state) => {
    return state.countOpenLeads
  },
}

export const mutations = {
  SET_VISIBLE: (state, payload) => {
    state.isMenuVisible = payload
  },
  TOGGLE_VISIBLE: (state) => {
    state.isMenuVisible = !state.isMenuVisible
  },

  SET_COUNT_OPEN_LEADS: (state, payload) => {
    state.countOpenLeads = payload
  },
}

export const actions = {
  async initMenu({ commit }) {
    if (this.$auth.loggedIn) {
      const count = await this.$axios.$get('/lead/open/count')

      commit('SET_COUNT_OPEN_LEADS', count)
    }
  },
}
