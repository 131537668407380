import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export class Header {
  private _store

  constructor(store: any) {
    this._store = store
  }

  get isVisible(): boolean {
    return this._store.getters['header/getVisible']
  }

  get isVisibleAdditionalMenu(): boolean {
    return this._store.getters['header/getVisibleAdditionalMenu']
  }

  get isHeaderTopPromoVisible(): boolean {
    return this._store.getters['promo/getHeaderTopPromoVisible']
  }

  get additionalMenu(): boolean {
    return this._store.getters['header/getAdditionalMenu']
  }

  get close() {
    return () => {
      this._store.commit('header/SET_VISIBLE', false)
    }
  }

  get open() {
    return () => {
      this._store.commit('header/SET_VISIBLE', true)
    }
  }

  get toggle() {
    return () => {
      this._store.commit('header/TOGGLE_VISIBLE')
    }
  }

  get setAdditionalMenu() {
    return (menu: any) => {
      this._store.commit('header/SET_ADDITIONAL_MENU', menu)
    }
  }

  get setVisibleAdditionalMenu() {
    return (state: boolean) => {
      this._store.commit('header/SET_VISIBLE_ADDITIONAL_MENU', state)
    }
  }
}

export default defineNuxtPlugin(({ store }, inject) => {
  inject('header', new Header(store))
})
