const author = {
  name: 'Author',
  authorName(user) {
    const author = Array.isArray(user) ? user[0] : user

    if (!author) {
      return ''
    }
    if (author.type === 'COMPANY' && author.company) {
      return author.company?.title ? author.company.title : author.username
    } else if (author.type === 'COMPANY') {
      return author.profile?.title ? author.profile.title : author.username
    } else if (author.profile?.firstName && author.profile?.lastName) {
      return `${author.profile.firstName} ${author.profile.lastName}`
    } else if (author.profile?.firstName) {
      return author.profile.firstName
    } else if (author.profile?.lastName) {
      return author.profile.lastName
    } else {
      return author.username
    }
  },

  avatar(user) {
    const author = Array.isArray(user) ? user[0] : user
    if (!author) {
      return ''
    }
    if (author.type === 'COMPANY' && author.company) {
      return author.company?.avatar
    } else {
      return author.profile?.avatar
    }
  },

  color(user) {
    const author = Array.isArray(user) ? user[0] : user
    if (!author) {
      return ''
    }
    if (author.type === 'COMPANY') {
      return author.company?.color
    } else {
      return author.profile?.color
    }
  },

  username(user) {
    const author = Array.isArray(user) ? user[0] : user

    if (!author) {
      return ''
    }
    return author.username
  },
}

export default (_app, inject) => {
  inject('author', author)
}
